import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { FaFacebook, FaApple, FaCheck } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import Swal from "sweetalert2";
import "./signinstyle.css";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { EncryptText } from "../../../Encrypt/CryptoEncryption";
import axios from "axios";
import FacebookButton from "../../../Global/AuthModals/Login/FaceBookLogin";
import GoogleButton from "../../../Global/AuthModals/Login/GoogleLogin";

function Sign_In() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = window.innerWidth <= 500;

  var headers = new Headers();
  headers.append("Client_ID", "MVOZ7rblFHsvdzk25vsQpQ==");
  headers.append("Content-Type", "application/json");

  const handleNext = async () => {
    if (step === 2) {
      try {
        const response = await axios.post(
          `${baseURL}/wl/loginDtls`,
          {
            username: EncryptText(email),
            password: EncryptText(password),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
            },
          }
        );
        // console.log(response.data);
        if (response.data.status == 200) {
          Cookies.set("token", `Bearer ${response.data.result.token}`);
          Cookies.set("email", response.data.result.userLoginResBean.email);
          Cookies.set(
            "cuetPromoFlag",
            response.data.result.userLoginResBean.cuetPromoFlag
          );
          Cookies.set("userId", response.data.result.userLoginResBean.userId);
          Cookies.set("subscription_redirection", `yes`);
          Cookies.set(
            "preferredCourseId",
            response.data.result.userLoginResBean.preferredCourseId
          );
          localStorage.setItem(
            "preferredCourseId",
            response.data.result.userLoginResBean.preferredCourseId
          );
          navigate("/student-dashboard");
        } else {
          // console.log(response.data);
          // console.error("Authentication failed");

        }
      } catch (error) {
        // console.error("Authentication failed", error);
        Swal.fire({
          icon: "error",
          title: "Unauthorised or Incorrect Login Details.",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        window.location.href = "/signin"
      }
    }

    setStep(step + 1);
  };
  const loginOtp = () => {
    navigate("/SignInOtp");
  };

  return (
    <div
      style={{
        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/cardimg/7a4c0d6ee7cceed8cf02a566d9596667.png")`,
        backgroundSize: "cover",
        height: "100%",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(123, 31, 162, 0.5)",
      }}
    >
      <div
        style={{
          backgroundSize: "cover",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          padding: "50px",
          backgroundColor: "rgba(123, 31, 162, 0.5)",
        }}
      >
        <Card
          style={{
            // width: "700px",
            padding: "50px",
            paddingBottom: "100px",
            borderRadius: "1px",
            backgroundColor: "#fff"
          }}
          className="cards-container"
        >
          <h4 className="sign_in_head text-center">{isSmallScreen ? "Sign In" : "Sign In to Your Account"}</h4>

          <div className="text-center mb-3">
            <div className="container">
              <div className="imagectn">
                <div className="circlespan">
                  {step >= 1 && (
                    <FaCheck
                      style={{
                        color: "white",
                        backgroundColor: "#71269C",
                        borderRadius: "50%",
                        height: "70%",
                        width: "70%",
                        padding: "8px",
                      }}
                    />
                  )}
                </div>
              </div>
              <span
                className="dotted-line"
              ></span>
              <div className="imagectn">
                <div className="circlespan">
                  {step >= 2 && (
                    <FaCheck
                      style={{
                        color: "white",
                        backgroundColor: "#71269C",
                        borderRadius: "50%",
                        height: "70%",
                        width: "70%",
                        padding: "8px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {isSmallScreen ? undefined : (<div className="container textctn">
              <p>Email</p>
              <p>Password</p>
            </div>)}

            {/* {Array.from({ length: 2 }).map((_, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <span
                  style={{
                    height: "2px",
                    width: "30px",
                    background: step > index ? "#71269C" : "gray",
                    display: "inline-block",
                    margin: "0 5px",
                  }}
                />
              )}
              <span
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  background: step > index ? "#71269C" : "white",
                  border: `2px solid ${step > index ? "#71269C" : "gray"}`,
                  color: step > index ? "#71269C" : "gray",
                  display: "inline-block",
                  textAlign: "center",
                  lineHeight: "30px",
                  fontSize: "16px",
                }}
              >
                {step > index + 1 && <FaCheck style={{ color: "white" }} />}
              </span>
            </React.Fragment>
          ))} */}
          </div>

          <Form className={isSmallScreen ? 'w-100' : ''} style={isSmallScreen ? {} : { width: '400px' }}>
            {step === 1 && (
              <Form.Group className="mb-3">
                <Form.Label>Enter Email / Mobile Number</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter Email / Mobile Number"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    height: "50px",
                    borderRadius: "0",
                  }}
                />
                <div className={isSmallScreen ? "mt-2" : "d-flex justify-content-between mt-2"}>
                  <Form.Check
                    type="checkbox"
                    label="Remember me"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <Link
                    to="/forgot-password"
                    className=""
                    style={{ color: "#7B1FA2", textDecoration: "underline" }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </Form.Group>
            )}

            {step === 2 && (
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    height: "50px",
                    borderRadius: "0",
                  }}
                />
                <div className={isSmallScreen ? "mt-2" : "d-flex justify-content-between mt-2"}>
                  <Form.Check
                    type="checkbox"
                    label="Remember me"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <Link
                    to="/forgot-password"
                    className=""
                    style={{ color: "#7B1FA2", textDecoration: "underline" }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </Form.Group>
            )}

            <div className="d-flex justify-content-between align-items-center">
              <Button
                variant="secondary"
                size="lg"
                style={{
                  width: "100%", // Full width by default
                  maxWidth: "48%", // Maximum width on larger screens
                  marginBottom: "10px", // Optional margin between buttons on small screens
                  background: step === 1 ? "#8C94A314" : "#7B1FA2",
                  color: step === 1 ? "rgb(0, 0, 0)" : "#FFF",
                  fontWeight: "semibold",
                }}
                onClick={handleNext}
              >
                {step === 1 ? "Next" : "Sign In"}
              </Button>

              <Button
                variant="primary"
                size="lg"
                style={{
                  width: "100%", // Full width by default
                  maxWidth: "48%", // Maximum width on larger screens
                  marginBottom: "10px", // Optional margin between buttons on small screens
                  background: "#FFFFFF",
                  border: "2px solid #71269C",
                  color: "#71269C",
                  paddingLeft: isSmallScreen ? "5px" : "auto",
                  paddingRight: isSmallScreen ? "5px" : "auto"
                }}
                onClick={loginOtp}
              >
                <span className="loginotpbutton">{isSmallScreen ? 'OTP Login' : 'Login With OTP'}</span>
              </Button>
            </div>

            {/* <div className="container">
              <span
                style={{ width: "40%", border: "1px solid #08080844" }}
              ></span>
              <p
                style={{
                  color: "gray",
                  fontWeight: "normal",
                  marginBlock: "20px",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                SIGN IN WITH
              </p>
              <span
                style={{ width: "40%", border: "1px solid #08080844" }}
              ></span>
            </div> */}

            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            > */}
            {/* <div style={{ flex: "0.25" }}>
                  <FcGoogle size={25} />
                </div>
                Google */}
            {/* <GoogleButton /> */}

            {/* <button className="providerbtn"> */}
            {/* <div style={{ flex: "0.25" }}> */}
            {/* <FaFacebook size={25} color="blue" /> */}
            {/* <FacebookButton /> */}
            {/* </div> */}
            {/* Facebook */}
            {/* </button> */}
            {/* <button className="providerbtn">
                <div style={{ flex: "0.25" }}>
                  <FaApple size={25} color="black" />
                </div>
                {isSmallScreen ? '' : 'Apple'}
              </button> */}

            {/* <Button
              variant="outline-primary"
              className="me-3"
              size="md"
              onClick={() => console.log("Google button clicked")}
            >
              <FcGoogle size={25} />
              Google
            </Button>
            <Button
              variant="outline-primary"
              className="me-3"
              size="md"
              onClick={() => console.log("Facebook button clicked")}
            >
              <FaFacebook size={25} />
              Facebook
            </Button>
            <Button
              variant="outline-primary"
              size="md"
              onClick={() => console.log("Apple button clicked")}
            >
              <FaApple size={25} color="black" />
              Apple
            </Button> */}
            {/* </div> */}
          </Form>

          <div className="span_login_msg mt-2 text-center">
            <span className="span_msg">
              Not registered yet?{" "}
              <Link to="/signup">
                <span className="span_login">Signup here</span>
              </Link>
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Sign_In;
