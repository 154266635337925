import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, lazy, useState, useEffect } from "react";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "./Pages/Landing/Landing";
import ViewTest from "./Pages/ViewTest/ViewTest";
import ExamTestInstructions from "./Pages/StudentMCQ/ExamTestInstructions";
import TestSubmit from "./Pages/TestSubmit/TestSubmit";
import ReviewTest from "./Pages/ReviewTest/ReviewTest";
import MCQ from "./Pages/MCQ/MCQ";
import Feedback from "./Pages/Feedback/Feedback";
import Gateway from "./Pages/Payment/Gateway";
import Invoice from "./Pages/Payment/Invoice";
import Teachers from "./Pages/Teachers/Teachers";
import IndividualTeacher from "./Pages/Teachers/IndividualTeacher";
import PaymentConfirmation from "./Pages/PaymentConfirmation/PaymentConfirmation";
import MySubscription from "./Pages/MySubscription/MySubscription";
import StudentMCQ from "./Pages/StudentMCQ/StudentMCQ";
import PrivateRoute from "./Utils/PrivateRoute";
import StudentExamTestMcq from "./Pages/StudentMCQ/StudentExamTestMcq";
import ExamQuizSummuryMCQ from "./Pages/StudentMCQ/ExamQuizSummury";
import SubmitExam from "./Pages/StudentMCQ/SubmitExam";
import StudentFeedback from "./Pages/StudentMCQ/StudentFeedback";
import FeedbackForm from "./Pages/StudentMCQ/FeedbackForm";
import ExamViewTest from "./Pages/StudentMCQ/ExamViewTest";
import ShowSubscriptionModal from "./Components/NewLanding/ShowSubscriptionModal";
import BesstNewExamPage from "./Pages/SEOPages/BesstNewExamPage";
import BestOnlinePlatform from "./Pages/SEOPages/BestOnlinePlatform";
import FreeOnlineTests from "./Pages/SEOPages/FreeOnlineTests";
import CuetExam from "./Pages/SEOPages/CuetExam";
import CuetApplicationForm from "./Pages/SEOPages/CuetApplicationForm";
import StartTestReview from "./Pages/SEOPages/StartTestReview";
import Reviews from "./Pages/Landing/LandingPageComponents/Reviews";
import ViewAllExamTest from "./Pages/SEOPages/ViewAllExamTest";
import CuetOnlineTestPaper from "./Pages/SEOPages/CuetOnlineTestPaper";
import StudyWithCUET from "./Pages/SEOPages/StudyWithCUET";
import CompetitiveExamSeo from "./Pages/SEOPages/CompetitiveExamSeo";
import EducationalplatformSeo from "./Pages/SEOPages/EducationalplatformSeo";
import PdfTest from "./Pages/StudentMCQ/PdfTest";
import Header from "../src/Components/Global/Navigation/Header";
import MockPayment from "./Pages/Payment/MockPayment";
import Blogs from "./Pages/Blog/Blogs";
// import Header from "../Components/Global/Navigation/Header";
import {
  faqRoute,
  mcqTest,
  payHistory,
  privacyPolicy,
  profile,
  register,
  studDashboard,
  subscriptions,
  termsAndConditions,
  testSubmit,
  viewTest,
  seoPageRoutes,
  routes,
} from "./RouteConstants";
import "./App.css";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermCondition from "./Pages/TermCondition";
import CuetRegistration from "./Pages/SEOPages/CuetRegistration";
import CuetExamSeo from "./Pages/SEOPages/CuetExamSeo";
import PrivateRouter from "./Utils/PrivateRouter";
// import Payment from "./Pages/Payment/Payment";
import BesstOnlineCoaching from "./Pages/SEOPages/BesstOnlineCoaching";
import SubjectiveQuestAns from "./Pages/SubjectiveQuestAns/SubjectiveQuestAns";
import LiveQuiz from "./Pages/LiveQuiz";
import LiveQuizLeaderBoard from "./Pages/LiveQuizLeaderBoard";
import LiveQuizInstructions from "./Pages/LiveQuizInstructions";
import Sign_up from "./Components/Copy/Auth/Signup/Sign_up";
import Sign_In from "./Components/Copy/Auth/Signin/Sign_in";
import Contact_us from "./Components/Copy/Pages/Contact_Us";
import About_us from "./Components/Copy/Pages/About_us";
import SignInOtp from "./Components/Copy/Auth/Signin/SignIn_OTP";
import ForgotPassword from "./Components/Copy/Auth/ForgotPassword/ForgotPassword";
import SuccessTories from "./Components/Copy/Pages/SuccessStory";
import CuetUGCourse from "./Components/Copy/Courses/CuetUGCourse";
import "./AppCopy.css";
import NavBar from "../src/Components/Copy/Navbar/Navbar";
import Footer from "../src/Components/Copy/AboutUs/AboutUs";
import BlogPage from "./Pages/Blog/Blog";
import BlogPage1 from "./Pages/Blog/Blog1";
import BlogPage2 from "./Pages/Blog/Blog2";
import BlogPage3 from "./Pages/Blog/Blog3";
import Testimonials from "./Components/Copy/Pages/Testimonial";
import CurrentAffairs from "./Components/Copy/Pages/Current_Affairs";
import Class12 from "./Components/Copy/Class12/Class12";
import Class10 from "./Components/Copy/Class10/Class10";
import News from "./Components/Copy/Pages/News";
import ServiceOne from "./Pages/Services/Service12";
import Cookies from "js-cookie";
import ServiceTwo from "./Pages/Services/Service2";
import ServiceThree from "./Pages/Services/Service3";
import ServiceFour from "./Pages/Services/Service4";
import ServiceFive from "./Pages/Services/Services5";
import PaymentNew from "./Pages/Payment/PaymentNew";
import CookiePolicy from "./Pages/CookiePolicy";
import BlogPage0 from "./Pages/Blog/Blog0";
import Ctet from "./Components/Copy/Courses/Ctet";
import AddPageCuet from "./Components/Copy/Courses/ads/AddPageCuet";
import CUETPGCourse from "./Components/Copy/Courses/CUETPGCourse";
import Blog4 from "./Pages/Blog/Blog4";
import Blog5 from "./Pages/Blog/Blog5";
import Blog6 from "./Pages/Blog/Blog6";


const PaymentList = lazy(() => import("./Pages/PaymentHistory/PaymentList"));
const LandingNew = lazy(() => import("./Pages/NewLANDING/LandingNew"));
const Register = lazy(() => import("./Pages/Register/Register"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const Payment = lazy(() => import("./Pages/Payment/Payment"));
const FAQ = lazy(() => import("./Pages/FAQ/FAQ"));
const Mcq = lazy(() => import("./Pages/MCQ/MCQ"));
const StudentDashboard = lazy(() =>
  import("./Pages/StudentDashboard/StudentDashboard")
);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      // console.log("tokenValue", Cookies.get("token"));
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);
  return (
    <>
      <Router basename="/">
        {/* <Routes>
          <Route
            path={studDashboard}
            element={<PrivateRouter Component={StudentDashboard} />}
          ></Route>
          <Route
            path={profile}
            element={<PrivateRouter Component={Profile} />}
          />
          <Route
            path={payHistory}
            element={<PrivateRouter Component={PaymentList} />}
          ></Route>
        </Routes> */}

        <Suspense fallback={<div className="loading">Loading...</div>}>
          <Routes>
            <Route
              path={studDashboard}
              element={<PrivateRouter Component={StudentDashboard} />}
            ></Route>
            <Route
              path={profile}
              element={<PrivateRouter Component={Profile} />}
            />
            <Route
              path={payHistory}
              element={<PrivateRouter Component={PaymentList} />}
            ></Route>
            <Route
              path="/"
              exact
              element={
                <>
                  <NavBar />
                  <LandingNew />
                </>
              }
            />
            <Route
              path="/signup"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Sign_up />

                </>
              }
            />
            <Route
              path="/signin"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Sign_In />

                </>
              }
            />
            <Route
              path="/blog"
              exact
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BlogPage />

                </>
              }
            />
            <Route
              path="/blogs"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Blogs />
                </>
              }
            />
            <Route
              path="/cuet-preparation"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BlogPage0 />

                </>
              }
            />
            <Route
              path="/evevate-your-cuet-preperation"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BlogPage1 />

                </>
              }
            />
            <Route
              path="/anxiety-stress-management"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BlogPage2 />

                </>
              }
            />
            <Route
              path="/CUET-study-tips"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BlogPage3 />

                </>
              }
            />
            <Route
              path="/prepare-cuet-pg-english-literature"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Blog4 />

                </>
              }
            />
            <Route
              path="CUET-PG-History-Question-Paper"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Blog5 />

                </>
              }
            />
            <Route
              path="/cuet-pg-2025-guide"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Blog6 />

                </>
              }
            />
            <Route
              path="/cuet-mock-test"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ServiceOne />
                </>
              }
            />
            <Route
              path="/online-clases"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ServiceFive />
                </>
              }
            />
            <Route
              path="/download-study-material"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ServiceTwo />
                </>
              }
            />
            <Route
              path="/previous-year-question-bank"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ServiceFour />
                </>
              }
            />
            <Route
              path="/counseling-mock-registration"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ServiceThree />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Contact_us />

                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <About_us />

                </>
              }
            />
            <Route
              path="/success-stories"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <SuccessTories />

                </>
              }
            />
            <Route
              path="/cuetugcourse"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetUGCourse course={"CUET(UG) COURSE"} type={"UG"} />

                </>
              }
            />
            <Route
              path="/cuetexamcourse"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetUGCourse course={"CUET EXAM COURSE"} type={"PG"} />

                </>
              }
            />
            <Route
              path="/class12"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Class12 />

                </>
              }
            />
            <Route
              path="/class10"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Class10 />

                </>
              }
            />
            <Route
              path="/best-ctet-coaching-in-delhi"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Ctet />
                </>
              }
            />
            <Route
              path="/cuet-pg-online-coaching"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CUETPGCourse />

                </>
              }
            />
            <Route
              path="/moketest"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <About_us />

                </>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ForgotPassword />

                </>
              }
            />
            <Route
              path="/SignInOtp"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <SignInOtp />

                </>
              }
            />
            <Route
              path="/testimonials"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Testimonials />

                </>
              }
            />
            <Route
              path="/current-affairs"
              // element={

              //   <>
              //     {isLoggedIn ? <Header /> : <NavBar />}
              //     <PrivateRouter Component={CurrentAffairs} />
              //   </>
              // }
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CurrentAffairs />

                </>
              }
            />
            <Route
              path="/cuet2025_google"
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <AddPageCuet />

                </>
              }
            />
            <Route
              path="/news"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <News />

                </>
              }
            />
            <Route
              path={faqRoute}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <FAQ />

                </>
              }
            />
            <Route
              path={subscriptions}
              element={
                <>
                  <Payment />
                </>
              }
            />
            <Route
              path="/online-subscription"
              element={
                <>
                  <PaymentNew />
                </>
              }
            />
            <Route
              path="/mock-subscription"
              element={
                <>
                  <MockPayment />
                </>
              }
            />
            <Route
              path={mcqTest}
              element={
                <>
                  {/* <NavBar /> */}
                  <Mcq />

                </>
              }
            />
            <Route
              path={register}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Register />

                </>
              }
            />
            <Route
              path={viewTest}
              element={
                <>
                  {/* <NavBar /> */}
                  <ViewTest />

                </>
              }
            />
            <Route
              path={routes.subjectiveQuest}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <SubjectiveQuestAns />

                </>
              }
            />
            <Route
              path={privacyPolicy}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <PrivacyPolicy />

                </>
              }
            />
            <Route
              path={termsAndConditions}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <TermCondition />

                </>
              }
            />
            <Route
              path="cookie-policy"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CookiePolicy />

                </>
              }
            />
            <Route
              path={testSubmit}
              element={
                <>
                  {/* <NavBar /> */}
                  <TestSubmit />

                </>
              }
            />
            <Route
              path={routes.liveQuiz}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <LiveQuiz />

                </>
              }
            />

            {/* authorized routes */}

            <Route
              path={routes.liveQuizLeaderBoard}
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <LiveQuizLeaderBoard />

                </>
              }
            />

            <Route
              path="/StudentFeedback"
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <StudentFeedback />

                </>
              }
            />

            <Route
              path="/quiz-instructions"
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <LiveQuizInstructions />

                </>
              }
            />

            <Route
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <PrivateRoute />

                </>
              }
            >
              <Route
                path="/page"
                element={
                  <>
                    {isLoggedIn ? <Header /> : <NavBar />}
                    <Landing />

                  </>
                }
              />
              <Route
                path="/ShowSubscriptionModal"
                element={
                  <>
                    {isLoggedIn ? <Header /> : <NavBar />}
                    <ShowSubscriptionModal />

                  </>
                }
              />

              <Route
                path="/ExamTestInstructions"
                element={
                  <>
                    {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                    <ExamTestInstructions />

                  </>
                }
              />

              <Route
                path="/pdftestexam"
                element={
                  <>
                    {isLoggedIn ? <Header /> : <NavBar />}
                    <PdfTest />

                  </>
                }
              />
              <Route
                path="/paymentConfirmation"
                element={
                  <>
                    {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                    <PaymentConfirmation />

                  </>
                }
              />
              <Route
                path="/reviews"
                element={
                  <>
                    {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                    <Reviews />

                  </>
                }
              />

              <Route
                path="/studentMCQ"
                element={
                  <>
                    {isLoggedIn ? <Header /> : <NavBar />}
                    <MCQ />

                  </>
                }
              />
              <Route
                path="/examviewtest"
                element={
                  <>
                    {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                    <ExamViewTest />

                  </>
                }
              />
              <Route
                path="/reviewTest"
                element={
                  <>
                    {/* <NavBar /> */}
                    <ReviewTest />

                  </>
                }
              />
            </Route>

            <Route
              path="/studentExamTestMcq"
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <StudentExamTestMcq />

                </>
              }
            />

            <Route
              path="/FeedbackForm"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <FeedbackForm />

                </>
              }
            />

            <Route
              path="/mySubscriptions"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <MySubscription />

                </>
              }
            />

            <Route
              path="/ExamQuizSummuryMCQ"
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <ExamQuizSummuryMCQ />

                </>
              }
            />
            <Route
              path="/SubmitExam"
              element={
                <>
                  {/* {isLoggedIn ? <Header /> : <NavBar />} */}
                  <SubmitExam />

                </>
              }
            />
            <Route
              path="/feedback"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Feedback />

                </>
              }
            />
            <Route
              path="/payment-gateway"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Gateway />

                </>
              }
            />
            <Route
              path="/invoice"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Invoice />

                </>
              }
            />
            <Route
              path="/teachers"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <Teachers />

                </>
              }
            />
            <Route
              path="/individualteacher/:id"
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <IndividualTeacher />

                </>
              }
            />

            {/* seo page routes */}
            <Route
              path={seoPageRoutes.besstNewExamPage}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BesstNewExamPage />

                </>
              }
            />
            <Route
              path={seoPageRoutes.onlineCoaching}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BesstOnlineCoaching />

                </>
              }
            />
            <Route
              path={seoPageRoutes.bestOnlinePlatform}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <BestOnlinePlatform />

                </>
              }
            />
            <Route
              path={seoPageRoutes.bestOnlinePlatformCopy}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <FreeOnlineTests />
                </>
              }
            />
            <Route
              path={seoPageRoutes.competitiveExamSeo}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CompetitiveExamSeo />

                </>
              }
            />
            <Route
              path={seoPageRoutes.cuetApplicationForm}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetApplicationForm />

                </>
              }
            />
            <Route
              path={seoPageRoutes.cuetExam}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetExam />

                </>
              }
            />
            <Route
              path={seoPageRoutes.cuetExamSeo}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetExamSeo />

                </>
              }
            />
            <Route
              path={seoPageRoutes.cuetOnlineTestPaper}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetOnlineTestPaper />

                </>
              }
            />
            <Route
              path={seoPageRoutes.cuetRegistration}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <CuetRegistration />

                </>
              }
            />
            <Route
              path={seoPageRoutes.educationalplatformSeo}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <EducationalplatformSeo />

                </>
              }
            />
            <Route
              path={seoPageRoutes.startTestReview}
              element={
                <>
                  <StartTestReview />
                </>
              }
            />
            <Route
              path={seoPageRoutes.studyWithCUET}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <StudyWithCUET />

                </>
              }
            />
            <Route
              path={seoPageRoutes.viewAllExamTest}
              element={
                <>
                  {isLoggedIn ? <Header /> : <NavBar />}
                  <ViewAllExamTest />

                </>
              }
            />
            <Route path="*"
              element={
                <>
                  <NavBar />
                  <LandingNew />
                </>
              }
            />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </>
  );
}

export default App;
