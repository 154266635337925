import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Button, Form, Modal, } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ctetFormTodo } from '../../Redux/Thunks/Post/PostCTETFormThunk'
import Swal from "sweetalert2";
import { FormSchema } from './ValidationSchema.js'

export default function FormModel(props) {

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const { show, setShow } = props
    const [bool, setBool] = useState(false)

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        type: "CUET-PG-History-Question-Paper"
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: FormSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            // console.log(values)
            dispatch(ctetFormTodo({ 'values': values })).then((res) => add_res(res.payload, action))
        }
    })

    const add_res = (res, action) => {
        if (res?.status == 201) {
            action.resetForm()
            setBool(true)
            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Thankyou for Enroll We will get back to you shortly',
            //     position: 'center',
            //     showConfirmButton: false,
            //     timer: 1500
            // });
        } else if (res?.status == 400) {
            Swal.fire({
                icon: 'error',
                title: '400 BAD REQUEST',
                text: 'The request could not be understood by the server.',
                position: 'center',
                showConfirmButton: true
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',
                position: 'center',
                showConfirmButton: true
            });
        }
    }

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => { setShow(false); setBool(false) }}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title id="example-modal-sizes-title-lg" style={{ color: '#5a1e7d' }}>

                        {
                            !bool ?
                                <div style={{ fontWeight: '600', fontSize: '25px' }}>
                                    Submit your details to download papers
                                </div>
                                :
                                <div style={{ fontWeight: '600', fontSize: '25px' }}>
                                    Downloaded papers
                                </div>
                        }
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <Modal.Body>

                        {!bool ?
                            <div className='row'>

                                <div className="col-md-6 mt-2 mb-2">
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        placeholder={'Enter Name'}
                                        className='form_input'
                                        name={'name'}
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />
                                    {errors.name && touched.name ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.name}</span>) : null}
                                </div>



                                <div className="col-md-6 mt-2 mb-2">
                                    <Form.Control
                                        type="email"
                                        size="sm"
                                        placeholder={'Enter Email'}
                                        className='form_input'
                                        name={'email'}
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && touched.email ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.email}</span>) : null}
                                </div>

                                <div className="col-md-6 mt-2 mb-2">
                                    <Form.Control
                                        type="number"
                                        size="sm"
                                        placeholder={'Enter Phone'}
                                        className='form_input'
                                        name={'phone'}
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.phone && touched.phone ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.phone}</span>) : null}
                                </div>

                            </div>
                            :
                            <div>
                                <h3 className='text-center hthree_size text-secondary'>Thank you for submitting your details. You can download the PDF from here.</h3>
                                <div style={{ fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                    <h3 className="hfour_size mt-5" style={{}}> CUET PG History Question Paper 2024</h3>

                                    <p style={{ fontSize: '1rem' }} className="mt-3">
                                        {/* CUET PG History Question Paper 2024 (PDF) with Answer Key  */}
                                        <a
                                            href='pdf/CUET PG 2024 HISTORY.pdf'
                                            download
                                            className="ms-1"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <button
                                                className="btn btn-sm btn-danger mt-1"
                                                type='button'
                                            >
                                                Download PDF
                                            </button>
                                        </a>
                                    </p>
                                </div>
                                <div style={{ fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                    <h3 className="hfour_size mt-5" style={{ alignItems: 'start', }}> CUET PG History Question Paper 2023 (with Solutions)

                                    </h3>
                                    <p style={{ fontSize: '1rem', alignItems: 'center' }} className="mt-3">
                                        {/* CUET PG History Question Paper 2023 (PDF) with Answer Key  */}
                                        <a
                                            href="pdf/CUET PG 2023 HISTORY.pdf"
                                            download
                                            className="ms-1"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <button
                                                className="btn btn-sm btn-danger mt-1"
                                                type='button'
                                            >
                                                Download PDF
                                            </button>
                                        </a>

                                    </p>
                                </div>
                                <div style={{ fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                    <h3 className="hfour_size mt-5" style={{ alignItems: 'start', display: 'flex' }}> CUET PG History Question Paper 2022 (with Solutions)</h3>
                                    <p className="mt-3">
                                        {/* CUET PG History Question Paper 2022 (PDF) with Answer Key  */}
                                        <a
                                            href="/pdf/CUET PG 2022 HISTORY.pdf"
                                            download
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <button
                                                className="btn btn-sm btn-danger "
                                                type='button'
                                            >
                                                Download PDF
                                            </button>
                                        </a>

                                        <br />
                                        <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>

                                        </span>

                                    </p>
                                </div>


                            </div>
                        }
                    </Modal.Body>
                    {bool ?
                        '' :

                        <Modal.Footer>
                            <Button style={{ fontSize: '12px' }} type='submit' size='sm' variant="primary" className="reg_btn ms-3 mb-2 mt-2" >Submit</Button>
                        </Modal.Footer>
                    }
                </Form>
            </Modal>
        </>
    )
}
