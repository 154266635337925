import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ctetFormTodo = createAsyncThunk('ctetForm', async (data) => {

    try {
        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + `/leadform/insert`,
            data: {
                "firstName": data?.values?.first_name,
                "lastName": data?.values?.last_name,
                "email": data?.values?.email,
                "phoneNo": data?.values?.phone,
                "message": data?.values?.message,
                "type": data?.values?.type
            },
        });
        return res.data; // Return only the serializable data
    } catch (error) {
        throw error.response.data; // Optionally return serializable error data
    }
});