import Author from "../../Assets/images/author.png";
import BlogImage from "../../Assets/images/blog-image.png";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Container, Row, Col, Image, Accordion, Button } from "react-bootstrap";
import axios from "axios";
import Blog from "../../Components/Copy/Blog/Blog";

function BlogPage2() {
    const isSmallScreen = window.innerWidth <= 500;
    const faqQuestions = [
        {
            id: 1,
            faq: "Are there any free demo classes?",
            answer: "Yes, there will be free practice papers",
        },
        {
            id: 2,
            faq: "For which standard the courses are available?",
            answer: "We provide guidance for CUET (UG) 2022.",
        },
        {
            id: 3,
            faq: "Do I need to subscribe?",
            answer: "Yes, you need to subscribe for it.",
        },
        {
            id: 4,
            faq: "How can we contact and report an error, if found?",
            answer:
                "A student can contact with BESST team on given whatsapp number and also via email.",
        },
        {
            id: 5,
            faq: "Is language paper available for CUET?",
            answer:
                "Yes, languages like Assamese, Bengali, Hindi, English are available.",
        },
        {
            id: 6,
            faq: "Can the practice papers and video after the live classes be downloaded?",
            answer: "No, it can only be viewed.",
        },
        {
            id: 7,
            faq: "Can the subscription once made be cancelled?",
            answer: "No, once the subscription is done it cannot be cancelled.",
        },
        {
            id: 8,
            faq: "Can I change my subject after submission?",
            answer: "Yes, but within 3 days of subscription.",
        },
        {
            id: 9,
            faq: "Can I change my password?",
            answer: "Yes.",
        },
    ];
    return (
        <>
            <div className="blog-bg container-fluid mb-5">
                <div className="Contact_content">Blogs</div>
                <div className="Contact_slashContent">Home / Blogs</div>
            </div>
            {isSmallScreen ? (
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <div className="p-4">
                            <div>
                                <h1>CUET Exam Anxiety and Stress Management Techniques</h1>
                            </div>
                            <div className="my-4">
                                <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                                <div className="author-name">By Chinmoy Mohan</div>
                                <div className="text-secondary"><small>Published on November 14,2023</small></div>
                                <div className="text-secondary"><small>2min read</small></div>
                            </div>
                            <img src={BlogImage} className="blog-img" alt="author" />
                            <p style={{ textAlign: "justify" }}>
                                <span style={{ fontWeight: "600" }}>Conquering CUET Exam Anxiety: Essential Stress Management Techniques for
                                    Success</span>
                                The Common University Entrance Test (CUET) is a gateway to prestigious universities in
                                India, and preparation undoubtedly brings excitement and ambition. However, it's also
                                natural for students to experience CUET exam anxiety, a potent mix of stress, fear, and
                                worry that can hinder performance. Let's face it, the pressure to excel can feel
                                overwhelming, impacting focus, clarity, and sleep. But overcoming CUET exam stress is
                                achievable, and it starts with understanding its impact and equipping yourself with effective
                                stress management techniques
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                The Common University Entrance Test (CUET) is a gateway to prestigious universities in
                                India, and preparation undoubtedly brings excitement and ambition. However, it's also
                                natural for students to experience CUET exam anxiety, a potent mix of stress, fear, and
                                worry that can hinder performance. Let's face it, the pressure to excel can feel
                                overwhelming, impacting focus, clarity, and sleep. But overcoming CUET exam stress is
                                achievable, and it starts with understanding its impact and equipping yourself with effective
                                stress management techniques
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                BESST is designed to meet the specific needs of SEBA students. We provide a wide range of
                                resources, including study materials for various subjects, exam tips, and solutions to previous years'
                                question papers. We understand the nuances of the SEBA Class 10 curriculum and exam patterns,
                                and our content is tailored to address these intricacies.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                One of the key advantages of BESST is the focus on exam strategies. We offer insights into time
                                management, question-solving techniques, and how to approach different subjects. All this
                                information aims to empower you to perform your best in the SEBA Class 10 exams.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                As you navigate through this crucial phase of your education, BESST will serve as your trusted
                                companion, ensuring that you are well-prepared, confident, and ready to excel in the SEBA Class 10
                                exams. Let's embark on this academic journey together.
                            </p>
                            <div className="blog-faq">
                                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                                    <div>
                                        <h2 className="">FAQs</h2>
                                    </div>
                                </Col>

                                <Col md={12} sm={12} xs={12} className="faq_margin">
                                    {faqQuestions.map((item) => (
                                        <Accordion
                                            data-responsive-accordion-tabs="-medium-tabs large-tabs"
                                            defaultActiveKey={item.id === 1 ? "0" : "1"}
                                            key={item.id}
                                            className="w-100"
                                        >
                                            <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                                                <Accordion.Header className="accordion_header w-100">
                                                    {item.id}. {item.faq}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <p>{item.answer}</p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    ))}
                                </Col>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <div className="p-4">
                            <h4>RELATED BLOGS</h4>
                            <div className="blog-preview-container">
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">December 23,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-PG-History-Question-Paper"} style={{ cursor: "pointer" }}>
                                            CUET PG History Question Paper: Download with Answer Key PDF
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 07,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/prepare-cuet-pg-english-literature"} style={{ cursor: "pointer" }}>
                                            How to prepare for CUET PG English Literature
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">October 28,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-pg-online-coaching"} style={{ cursor: "pointer" }}>
                                            CUET PG Online Coaching: Your Path to Success
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/blog"} style={{ cursor: "pointer" }}>
                                            Class 10 SEBA Preparation Resources: Excel with BESST
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/evevate-your-cuet-preperation"} style={{ cursor: "pointer" }}>
                                            Elevate Your CUET Preparation: BESST's Authentic CUET Mock Tests
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/anxiety-stress-management"} style={{ cursor: "pointer" }}>
                                            CUET Exam Anxiety and Stress Management Techniques
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-study-tips"} style={{ cursor: "pointer" }}>
                                            How to Prepare for CUET: Mastering the Exam with Proven Strategies
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) :
                (<div className="blog-container">
                    <div className="blog-content">
                        <div>
                            <h1>CUET Exam Anxiety and Stress Management Techniques</h1>
                        </div>
                        <div className="author">
                            <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                            <div className="author-name">By Chinmoy Mohan</div> |
                            <div>Published on November 14,2023</div> | <div>2min read</div>
                        </div>
                        <img src={BlogImage} className="blog-img" alt="author" />
                        <p style={{ textAlign: "justify", }}>
                            <span style={{ fontWeight: "600" }}>Conquering CUET Exam Anxiety: Essential Stress Management Techniques for Success</span><br />
                            The Common University Entrance Test (CUET) is a gateway to prestigious universities in
                            India, and preparation undoubtedly brings excitement and ambition. However, it's also
                            natural for students to experience CUET exam anxiety, a potent mix of stress, fear, and
                            worry that can hinder performance. Let's face it, the pressure to excel can feel
                            overwhelming, impacting focus, clarity, and sleep. But overcoming CUET exam stress is
                            achievable, and it starts with understanding its impact and equipping yourself with effective
                            stress management techniques
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span style={{ fontWeight: "600" }}>Understanding CUET Exam Anxiety:</span><br />
                            CUET exam anxiety manifests in various ways. You might experience racing thoughts,
                            difficulty concentrating, physical symptoms like headaches or stomachaches, and even
                            sleep disturbances. It's crucial to acknowledge these signs and recognize that anxiety is a
                            common reaction to high-stakes situations like the CUET
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span style={{ fontWeight: "600" }}>Don't let anxiety define your journey!</span> Embrace it as a natural hurdle and empower
                            yourself with strategies to manage it effectively
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span style={{ fontWeight: "600" }}>Essential Stress Management Techniques for CUET Success:</span><br />
                            <ul>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>1. Normalize and Acknowledge:</span>  Talking about your anxiety openly with friends, family, or
                                        a counselor can ease the burden and offer valuable support. Remember, you're not alone
                                        in this, and sharing your feelings opens doors to guidance and understanding.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>2. Prioritize Self-Care: </span> Take care of your physical and mental well-being. Ensure
                                        adequate sleep, engage in regular exercise (even a brisk walk can work wonders!), and
                                        eat healthy meals. Remember, a nourished body and mind are better equipped to handle
                                        stress.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>3. Breathe Easy: </span> Deep breathing exercises are your secret weapon against anxiety.
                                        Practice techniques like the 4-7-8 method or mindful breathing to activate your body's
                                        relaxation response and calm your nerves.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>4. Plan and Structure Your Day:  </span>  Create a realistic study schedule with breaks and buffer
                                        time to avoid feeling overwhelmed. Sticking to a plan promotes a sense of control and
                                        reduces last-minute anxiety spikes.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>5. Practice Time Management:  </span> Master the art of prioritizing tasks and allocating
                                        dedicated time for studying, relaxing, and sleeping. Utilize tools like Pomodoro timers to
                                        stay focused and avoid procrastination, which can fuel anxiety
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>6. Positive Self-Talk:  </span>  Challenge negative thoughts with a dose of self-compassion.
                                        Replace "I can't" with "I can do this with effort" and "I'm going to fail" with "I've come this
                                        far, and I have the potential to succeed." Positive affirmations can reframe your mindset
                                        and boost confidence.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>7. Visualize Success:  </span>   Imagine yourself calmly taking the CUET exam, answering
                                        questions confidently, and achieving your desired score. Visualization techniques can trick
                                        your brain into believing in your capabilities and reduce pre-exam stress.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>8. Disconnect and Recharge:  </span> Take breaks from social media and news overload. Step
                                        away from screens and give your mind space to rest and recharge. Spending time in
                                        nature, listening to calming music, or engaging in hobbies you enjoy can significantly
                                        reduce stress levels.
                                    </p>
                                </li>
                                <li>
                                    <p style={{ textAlign: "justify" }}>
                                        <span style={{ fontWeight: "600" }}>9. Seek Professional Help:   </span>If anxiety feels overwhelming and interferes with your daily
                                        life, don't hesitate to seek professional help. Talk to a therapist or counselor who can
                                        provide personalized guidance and support to manage your stress effectively.
                                    </p>
                                </li>
                            </ul>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span style={{ fontWeight: "600" }}>Remember: You are not defined by your exam score. </span>The CUET is just one step in
                            your academic journey. Focus on the process of learning, celebrate your progress, and
                            trust your abilities. With dedication, self-care, and these essential stress management
                            techniques, you can conquer CUET exam anxiety and walk into the exam hall with
                            confidence, ready to shine.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span style={{ fontWeight: "600" }}>Bonus Tip: </span>Explore cuet stress management tips like mindfulness meditation, yoga, or
                            journaling to discover your personalized relaxation routine. Remember, what works for one
                            person might not work for another, so experiment and find techniques that resonate with
                            you.
                            By implementing these strategies and prioritizing your well-being, you can transform
                            anxiety into a motivator for growth and achieve your desired outcome in the CUET. Believe
                            in yourself, stay calm, and ace your exam!
                        </p>
                        {/* <div className="blog-faq">
                            <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                                <div>
                                    <h2 className="">FAQs</h2>
                                </div>
                            </Col>

                            <Col md={12} sm={12} xs={12} className="faq_margin">
                                {faqQuestions.map((item) => (
                                    <Accordion
                                        data-responsive-accordion-tabs="-medium-tabs large-tabs"
                                        defaultActiveKey={item.id === 1 ? "0" : "1"}
                                        key={item.id}
                                        className="w-100"
                                    >
                                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                                            <Accordion.Header className="accordion_header w-100">
                                                {item.id}. {item.faq}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{item.answer}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                            </Col>
                        </div> */}
                    </div>

                    <div className="right-pane">
                        <h4>RELATED BLOGS</h4>
                        <div className="blog-preview-container">
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">December 23,2024</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-PG-History-Question-Paper"} style={{ cursor: "pointer" }}>
                                        CUET PG History Question Paper: Download with Answer Key PDF
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 07,2024</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/prepare-cuet-pg-english-literature"} style={{ cursor: "pointer" }}>
                                        How to prepare for CUET PG English Literature
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">October 28,2024</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-pg-online-coaching"} style={{ cursor: "pointer" }}>
                                        CUET PG Online Coaching: Your Path to Success
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">August 07,2024</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-preparation"} style={{ cursor: "pointer" }}>
                                        Mastering CUET: The Ultimate Guide to CUET Preparation and Success
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">August 07,2024</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-preparation"} style={{ cursor: "pointer" }}>
                                        Mastering CUET: The Ultimate Guide to CUET Preparation and Success
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/blog"} style={{ cursor: "pointer" }}>
                                        Class 10 SEBA Preparation Resources: Excel with BESST
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/evevate-your-cuet-preperation"} style={{ cursor: "pointer" }}>
                                        Elevate Your CUET Preparation: BESST's Authentic CUET Mock Tests
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/anxiety-stress-management"} style={{ cursor: "pointer" }}>
                                        CUET Exam Anxiety and Stress Management Techniques
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-study-tips"} style={{ cursor: "pointer" }}>
                                        How to Prepare for CUET: Mastering the Exam with Proven Strategies
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }

            <Blog />
        </>
    );
}

export default BlogPage2;
