import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const adsPageTodo = createAsyncThunk('adsPage', async (data) => {
// console.log(data)
    try {
        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + `/googleAdsEnquiry/insert`,
            data: {
                "name": data?.values?.name,
                "email": data?.values?.email,
                "phoneNo": data?.values?.phone,

            },
        });
        return res.data; // Return only the serializable data
    } catch (error) {
        throw error.response.data; // Optionally return serializable error data
    }
});