import React from "react";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Row, Col, Card, Image } from "react-bootstrap";
import Blog from "../../Components/Copy/Blog/Blog";
import { Helmet } from "react-helmet-async";

export default function Blog4() {

    return (
        <>
            <Helmet>
                <meta charSet='utf-8' />
                {/* <meta
                    name='keyword'
                    content='CUET Exam Preparation Course Online in India'
                /> */}
                <meta
                    name='description'
                    content='Discover effective strategies and resources for preparing for the CUET PG English Literature exam. Learn tips on mastering the syllabus, time management, and scoring high'
                />
                <title>How to prepare for CUET PG English Literature</title>
                {/* <link
                    rel='canonical'
                    href='https://besst.in//registration/blog/2023/dynamic/cuet-courses'
                /> */}
            </Helmet>
            <div className="blog-bg container-fluid mb-5">
                <div className="Contact_content">Blogs</div>
                <div className="Contact_slashContent">Home / Blogs / How to prepare for CUET PG English Literature</div>
            </div>
            <div className="row">
                {/* <div className="col-md-12"> */}

                <div className="col-md-7 col-lg-7 col-sm-11 p-5 ms-2 me-3 ms-sm-4" >
                    <div>
                        <h1>How to Prepare for CUET PG English Literature: A Complete Guide for Success</h1>
                    </div>
                    <div className="author mt-3">
                        <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                        <div className="author-name">By Chinmoy Mohan</div> |
                        <div>Published on November 07, 2024</div> | <div>2min read</div>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/assets/online_pg/pexels-ketut-subiyanto-4623355.jpg`} className="w-100 h-60 mt-3" alt="author" style={{ objectFit: 'cover' }} />
                    <p style={{ textAlign: "justify" }} className="pt-4">
                        Discover effective strategies and resources for preparing for the CUET PG
                        English Literature exam. Learn tips on mastering the syllabus, time management, and scoring high
                    </p>
                </div>

                <div className="col-md-4 col-lg-4 col-sm-11 p-4 ms-sm-4">
                    <h4>RELATED BLOGS</h4>
                    <div className="blog-preview-container">
                        <div className="blog-preview">
                            <img
                                src={BlogPreviewImage}
                                className="blog-preview-image"
                                alt="thumbnail"
                            />
                            <div className="">
                                <h5 className="blog-date">December 23,2024</h5>
                                <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-PG-History-Question-Paper"} style={{ cursor: "pointer" }}>
                                    CUET PG History Question Paper: Download with Answer Key PDF
                                </h5>
                            </div>
                        </div>
                        <div className="blog-preview">
                            <img
                                src={BlogPreviewImage}
                                className="blog-preview-image"
                                alt="thumbnail"
                            />
                            <div className="">
                                <h5 className="blog-date">November 07,2024</h5>
                                <h5 className="blog-preview-title" onClick={() => window.location.href = "/prepare-cuet-pg-english-literature"} style={{ cursor: "pointer" }}>
                                    How to prepare for CUET PG English Literature
                                </h5>
                            </div>
                        </div>
                        <div className="blog-preview">
                            <img
                                src={BlogPreviewImage}
                                className="blog-preview-image"
                                alt="thumbnail"
                            />
                            <div className="">
                                <h5 className="blog-date">October 28,2024</h5>
                                <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-pg-online-coaching"} style={{ cursor: "pointer" }}>
                                    CUET PG Online Coaching: Your Path to Success
                                </h5>
                            </div>
                        </div>

                        <div className="blog-preview">
                            <img
                                src={BlogPreviewImage}
                                className="blog-preview-image"
                                alt="thumbnail"
                            />
                            <div className="">
                                <h5 className="blog-date">August 07,2024</h5>
                                <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-preparation"} style={{ cursor: "pointer" }}>
                                    Mastering CUET: The Ultimate Guide to CUET Preparation and Success
                                </h5>
                            </div>
                        </div>
                        <div className="blog-preview">
                            <img
                                src={BlogPreviewImage}
                                className="blog-preview-image"
                                alt="thumbnail"
                            />
                            <div className="">
                                <h5 className="blog-date">November 14, 2023</h5>
                                <h5 className="blog-preview-title" onClick={() => window.location.href = "/blog"} style={{ cursor: "pointer" }}>
                                    Class 10 SEBA Preparation Resources: Excel with BESST
                                </h5>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="container-fluid">

                    {/* .......How to Prepare for CUET PG English........ */}

                    <div className="col-md-12 mt-5">
                        <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                            {/* <div > */}
                            <Image
                                // style={{ padding: '50px' }}
                                src={`${process.env.PUBLIC_URL}/assets/online_pg/Distance_Learning_vs_Online_Learning1.jpg`}
                                className="courseimg img-fluid"
                                rounded
                                alt="CTET"
                                style={{ objectFit: 'cover', width: '600px' }}
                            />


                            <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                                <h1 className="hone_size text-start" > How to Prepare for CUET PG English Literature: Top Tips and Strategies</h1>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Preparing for the CUET PG English Literature exam requires a strategic approach to cover the vast
                                    syllabus efficiently. Knowing how to prepare for CUET PG English Literature can be challenging,
                                    but with the right plan, students can build confidence and score well. This blog will guide you
                                    through essential preparation strategies, resources, and tips for success in CUET PG English
                                    Literature.
                                </p>

                                <h2 className="htwo_size mt-5" style={{ alignItems: 'start', display: 'flex', borderBottom: '2px solid #5a1e7d', textAlign: 'start' }}>Understanding the CUET PG English Literature Syllabus</h2>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    The CUET PG English Literature exam is designed to test students' grasp of literary history,
                                    analysis, and criticism. Understanding the syllabus is the first step in knowing how to prepare for
                                    CUET PG English Literature effectively.
                                </p>


                            </div>
                            {/* </div> */}
                        </div>
                    </div>

                    {/* .... Key Topics Covered........... */}

                    <div className="container mt-4">

                        <Row className="gs-5 ge-5">
                            {/* Breadcrumb Card */}
                            <Col md={12} className='text-center mt-4 mb-4'>
                                <h2 className='htwo_size '> Key Topics Covered</h2>
                                <p style={{ fontSize: '1.2rem' }} className="mt-3">
                                    To help you stay organized, here are the primary areas that students should focus on
                                </p>
                            </Col>

                            <Col md={4} >
                                <Card className="custom-card text-center h-100 ">
                                    <div className="red-banner">
                                        <h5 className="hfive_size" >Literary Theory and Criticism</h5>

                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text >
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                An essential part of English literature, covering theories,
                                                schools of thought, and key critics
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Search Field Card */}
                            <Col md={4} >
                                <Card className="custom-card text-center  h-100">
                                    <div className="red-banner">

                                        <h5 className="hfive_size" >British Literature</h5>

                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text>
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                From Old English to Contemporary Literature, covering major writers,
                                                movements, and genres.
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Pagination Card */}
                            <Col md={4}>
                                <Card className="custom-card text-center  h-100">
                                    <div className="red-banner">
                                        <h5 className="hfive_size" >Indian English Literature</h5>

                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text>
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                Understanding Indian authors and their contributions to English
                                                literature.
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={2}
                            ></Col>
                            <Col md={4}>
                                <Card className="custom-card text-center  h-100">
                                    <div className="red-banner">
                                        <h5 className="hfive_size" >American and World Literature</h5>

                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text>
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                A look at international writers, movements, and influential
                                                works
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="custom-card text-center  h-100">
                                    <div className="red-banner">
                                        <h5 className="hfive_size" >Poetry, Drama, and Fiction Analysis</h5>

                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text>
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                Analysis of form, style, themes, and techniques in
                                                poetry, drama, and fiction.
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={2}
                            ></Col>
                        </Row>
                    </div>

                    {/* ......Step-by-Step Guide on How......... */}

                    <div className="col-md-12 mt-5">
                        <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                            {/* <div > */}



                            <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                                <h2 className="htwo_size text-start" > Step-by-Step Guide on How to Prepare for CUET PG English Literature</h2>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Now that you know what topics to cover, here’s a step-by-step approach to prepare for CUET PG
                                    English Literature in an organized and effective manner.
                                </p>

                                <h3 className="hthree_size mt-5" style={{ alignItems: 'start', display: 'flex' }}> Step 1 – Familiarize Yourself with the Exam Pattern</h3>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Understanding the CUET PG exam pattern is crucial for any candidate. Knowing how many
                                    sections there are, the types of questions, and the allotted time for each section will help you
                                    strategize your preparation.
                                    <br /> <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>- Question Types:
                                    </span> Primarily objective, with multiple-choice questions.

                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>-
                                        Sections:
                                    </span> English Literature and General Knowledge (if applicable).

                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>-
                                        Time Allotment:</span> Manage your time based on the number of questions and difficulty level.
                                </p>


                                <h3 className="hthree_size mt-5" style={{ alignItems: 'start', display: 'flex' }}> Step 2 – Create a Study Schedule</h3>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Having a structured study plan is essential when deciding how to prepare for CUET PG English
                                    Literature. Here are some tips to create an effective schedule:
                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>-
                                        Break Down the Syllabus:
                                    </span>
                                    Divide the syllabus into manageable parts and tackle one section at a time.
                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>-
                                        Daily Goals:
                                    </span>
                                    Set realistic daily goals, such as reading one chapter or analyzing a set number of poems.
                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>-
                                        Revision Time:
                                    </span>
                                    Reserve time each week for revision to keep your knowledge fresh.
                                </p>


                                <h3 className="hthree_size mt-5" style={{ alignItems: 'start', display: 'flex' }}> Step 3 – Use Quality Study Materials</h3>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Choosing the right study materials is critical to success in CUET PG English Literature.<br /> <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>1. Primary Texts:
                                    </span>
                                    Read original texts by authors like Shakespeare, Wordsworth, and Eliot to
                                    understand their work deeply.

                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>
                                        2. Study Guides:
                                    </span>
                                    Use study guides and notes specifically designed for CUET PG English
                                    Literature.

                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>
                                        3. Online Resources:
                                    </span>
                                    Platforms like YouTube, Coursera, and edX offer free lectures on literary
                                    criticism, analysis, and more. For focused and specialized preparation for the CUET(PG) English
                                    exam, BESST.in stands out as one of the best platforms, offering tailored resources, mock tests, and
                                    expert guidance to help students succeed .
                                </p>


                                <h3 className="hthree_size mt-5" style={{ alignItems: 'start', display: 'flex' }}> Step 4 – Practice Time Management</h3>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Time management is a critical skill in CUET PG preparation. Divide your time between reading,
                                    analyzing, and practicing questions to ensure well-rounded preparation.<br /> <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>- Timed Practice Sessions:
                                    </span> Set a timer while solving previous years’ question papers.<br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>
                                        - Prioritize Topics:
                                    </span>  Focus more on sections where you feel less confident and make time for
                                    reviewing frequently missed questions.<br />

                                </p>


                            </div>


                            <Image
                                // style={{ padding: '50px' }}
                                src={`${process.env.PUBLIC_URL}/assets/online_pg/2250x1500_czy-warto-korzystac-ze-szkolen-online-ollh.jpg`}
                                className="courseimg img-fluid ms-3"
                                rounded
                                alt="CTET"
                                style={{ objectFit: 'cover', width: '600px' }}
                            />
                            {/* </div> */}
                        </div>
                    </div>

                    {/* .......Important Resources for CUET PG English Literature Preparation........ */}

                    <div fluid className=" mt-5 we-offer">
                        <div className="offering_container">
                            <div className="offering-heading me-5">
                                <h2 className="h3_head text-start htwo_size">Important Resources for CUET PG English Literature Preparation</h2>
                                <p style={{ textAlign: "justify", fontSize: '1.2rem' }} className="mt-3">
                                    Access to the right resources will make your preparation smoother. Below are some key resources to utilize:
                                </p>
                            </div>

                            <div className="row row-flex">
                                <div className="card-container">

                                    <Card className="p-2 Ctet_Course_card match_height mb-3" style={{ border: '1px solid', overflow: 'visible' }}>
                                        <Image
                                            src={`${process.env.PUBLIC_URL}/assets/online_pg/Five-Ways-to-Engage-Students-in-an-Online-Learning-Environment.jpg`}
                                            alt="frame"
                                            className="img-course mt-0"
                                            fluid
                                            style={{ objectFit: 'cover', height: '250px' }}
                                        />
                                        <Card.Body>
                                            <div className="p-2">
                                                <h3
                                                    className="card-university-title hthree_size"
                                                    style={{ color: "#71269C" }}
                                                >
                                                    Recommended Books for CUET PG English Literature
                                                </h3>
                                                <p style={{ textAlign: "justify", fontSize: '1rem' }} className="card-university-title mt-3">
                                                    <br />1. "A Glossary of Literary Terms" by M.H. Abrams – Essential for understanding literary terms.
                                                    <br />2. "History of English Literature" by Edward Albert – A comprehensive overview of British literary history.
                                                    <br />3. "An Introduction to English Literature" by Hudson – Great for foundational learning
                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Card>


                                    <Card className="p-2 Ctet_Course_card match_height mb-3" style={{ border: '1px solid', overflow: 'visible' }}>
                                        <Image
                                            src={`${process.env.PUBLIC_URL}/assets/online_pg/advantage online learning.jpg`}
                                            alt="frame"
                                            className="img-course mt-0"
                                            fluid
                                            style={{ objectFit: 'cover', height: '250px' }}
                                        />
                                        <Card.Body>
                                            <div className="p-2">
                                                <h3
                                                    className="card-university-title hthree_size"
                                                    style={{ color: "#71269C" }}
                                                >
                                                    Online Platforms and Apps
                                                </h3>
                                                <p style={{ textAlign: "justify", fontSize: '1rem' }} className="card-university-title mt-3">
                                                    <br /> 1. YouTube Channels: Channels like CrashCourse and Literature Studies are great for visual learners.

                                                    <br />2. Apps: Exam preparation apps like Unacademy, Byju’s, and the BESST App offer specific courses for CUET PG English Literature. The BESST App contains all the resources required for exam preparation, including mock tests, study materials, and expert guidance to help students excel

                                                    <br />3. PDF Notes and Mock Tests: Download study notes and attempt mock tests on educational websites like BESST.in, where students can enroll for comprehensive CUET PG mock tests and access valuable preparation resources

                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Card>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* .......How to Analyze Literature for CUET PG English Literature Exam........ */}

                    <div className="col-md-12 mt-5">
                        <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                            {/* <div > */}
                            <Image
                                // style={{ padding: '50px' }}
                                src={`${process.env.PUBLIC_URL}/assets/online_pg/gettyimages-692236966-612x612.jpg`}
                                className="courseimg img-fluid"
                                rounded
                                alt="CTET"
                                style={{ objectFit: 'cover', width: '600px' }}
                            />


                            <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                                <h2 className="htwo_size text-start" > How to Analyze Literature for CUET PG English Literature Exam </h2>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    A deep understanding of literature analysis is vital for students preparing for the CUET PG exam.</p>

                                <h3 className="hthree_size mt-5" style={{ alignItems: 'start', display: 'flex', borderBottom: '2px solid #5a1e7d', textAlign: 'start' }}>Key Steps for Literary Analysis</h3>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    <br />1. Identify Themes and Motifs: Focus on recurring themes, symbols, and motifs to understand the underlying message of a text.
                                    <br /> 2. Analyze Character Development: Observe character arcs and motivations to add depth to your understanding.
                                    <br />3. Study Literary Devices: Recognize metaphors, similes, alliteration, and other devices to enhance textual interpretation.

                                </p>


                            </div>
                            {/* </div> */}
                        </div>
                    </div>

                    {/* .......Practice with CUET PG English Literature Mock Tests........ */}
                    <div className="col-md-12 mt-5">
                        <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                            {/* <div > */}



                            <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                                <h2 className="htwo_size text-start" > Practice with CUET PG English Literature Mock Tests</h2>
                                <p style={{ fontSize: '1rem' }} className="mt-3">
                                    Mock tests are essential tools for CUET PG exam preparation, providing a realistic test environment that helps students gauge their progress.</p>

                                <h3 className="hthree_size mt-5" style={{ alignItems: 'start', display: 'flex' }}> Why Mock Tests are Important</h3>
                                <p style={{ fontSize: '1rem' }} className="mt-3">

                                    <br /> <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}> 1. Self-Assessment:
                                    </span>Mock tests allow you to evaluate your strengths and areas for improvement.
                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>
                                        2. Time Management Practice:
                                    </span> Practicing with time limits enables better time allocation during the actual exam.
                                    <br />
                                    <span style={{ color: 'black', fontWeight: '600', paddingTop: '5px' }}>
                                        3. Question Familiarity:</span> Mock tests expose you to a variety of questions, enhancing your adaptability and comprehension.
                                </p>




                            </div>


                            <Image
                                // style={{ padding: '50px' }}
                                src={`${process.env.PUBLIC_URL}/assets/online_pg/AdobeStock_863287291_Preview-transformed.jpeg`}
                                className="courseimg img-fluid ms-3"
                                rounded
                                alt="CTET"
                                style={{ objectFit: 'cover', width: '600px', marginLeft: "0px" }}
                            />
                            {/* </div> */}
                        </div>
                    </div>

                    {/* .....Tips for Effective Revision in CUET PG English Literature Preparation.......... */}

                    <div className="ctet_syllabus container mt-5 ">
                        <div className="align_center ml-2">
                            <h2 style={{ fontSize: '1.5em', marginTop: '15px', }} className='text-center htwo_size'>Tips for Effective Revision in CUET PG English Literature Preparation</h2>
                            <div md={11} sm={11} xs={11}>
                                <div className="intro_ctet">
                                    Revision is essential for retention and confidence-building. Here are some key revision tips for how to prepare for CUET PG English Literature:
                                </div>

                                <Row className="intro-cards-container m-2 row-flex">
                                    <Col lg={4} md={6} sm={12} xs={12} className='p-3'>
                                        <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px", height: '100%' }}>
                                            {/* <Row className="intro-center row-flex"> */}
                                            <div className="text-center col-md-12 p-3">
                                                <h3 className="user_name" style={{ borderBottom: '1px solid white' }}>Use Summary Notes</h3>
                                                <p className="mt-3" style={{ color: 'white' }}>
                                                    Create summary notes for each topic. Summarizing key points helps retain information and provides quick access during final review sessions.
                                                </p>
                                            </div>
                                            {/* </Row> */}
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className='p-3'>
                                        <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px", height: '100%' }}>
                                            {/* <Row className="intro-center row-flex"> */}
                                            <div className="text-center col-md-12 p-3">
                                                <h3 className="user_name" style={{ borderBottom: '1px solid white' }}>Group Discussion and Peer Learning</h3>
                                                <p className="mt-3" style={{ color: 'white' }}>
                                                    Discussing topics with peers is an excellent way to reinforce your learning. Sharing insights can provide new perspectives and clarify doubts.
                                                </p>
                                            </div>
                                            {/* </Row> */}
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} xs={12} className='p-3'>
                                        <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px", height: '100%' }}>
                                            {/* <Row className="intro-center row-flex"> */}
                                            <div className="text-center col-md-12 p-3">
                                                <h3 className="user_name" style={{ borderBottom: '1px solid white' }}>Flashcards for Literary Terms and Authors</h3>
                                                <p className="mt-3" style={{ color: 'white' }}>
                                                    Use flashcards for quick memorization of literary terms, famous works, and key authors. This is particularly helpful for last-minute revision.
                                                </p>
                                            </div>
                                            {/* </Row> */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>

                    {/* .........Common Mistakes to Avoid in CUET PG English Literature Preparation ...... */}

                    <div className="container mt-4" >

                        <Row className="gs-5 ge-5">
                            {/* Breadcrumb Card */}
                            <Col md={12} className='text-center mt-4 mb-4'>
                                <h2 className='htwo_size'>Common Mistakes to Avoid in CUET PG English Literature Preparation </h2>
                                <p style={{ textAlign: "justify", fontSize: '1.2rem' }} className="mt-3">
                                    Avoiding common pitfalls will keep you on track and enhance your chances of success.
                                </p>
                            </Col>


                            {/* Search Field Card */}
                            <Col md={4} >
                                <Card className="custom-card text-center  h-100">
                                    <div className="red-banner">
                                        <h3>Overlooking Primary Texts</h3>
                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text>
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                Focusing only on study guides can be a mistake. Always prioritize reading primary texts to develop a stronger understanding.
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} >
                                <Card className="custom-card text-center h-100 ">
                                    <div className="red-banner">

                                        <h3 >Skipping Literary Theory</h3>

                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text >
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                Literary theory is a crucial part of CUET PG English Literature, often overlooked by students. Understanding major theories and critics will enhance your exam answers.
                                            </p>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            {/* Pagination Card */}
                            <Col md={4}>
                                <Card className="custom-card text-center  h-100">
                                    <div className="red-banner">
                                        <h3 >Ignoring Mock Tests</h3>
                                    </div>
                                    <Card.Body className="custom-card-body">
                                        <Card.Text>
                                            <p style={{ textAlign: "justify", fontSize: '1rem' }} className="mt-3">
                                                Skipping mock tests can result in poor time management during the exam. Regular practice tests are essential to refine your strategy and identify weak areas.
                                            </p>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                    {/* .........Final Words on How to Prepare for CUET PG English Literature...... */}

                    <div className="col-md-12 mt-5">
                        <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                            <div className="cuetctn ms-0 me-0 me-sm-5 mb-5 ms-lg-5 mb-lg-0">
                                <h2 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='htwo_size'> Final Words on How to Prepare for CUET PG English Literature</h2>
                                <p style={{ textAlign: "justify", fontSize: '18px' }} className='mt-2 mb-2'>
                                    In summary, preparing for CUET PG English Literature requires dedication, time management, and a well-structured plan. By understanding the syllabus, following a strategic approach, and practicing consistently, students can boost their confidence and perform well on exam day. With online resources, study materials, and mock tests, how to prepare for CUET PG English Literature becomes a manageable and achievable goal.
                                </p>
                                <p style={{ textAlign: "justify", fontSize: '18px' }} className='mt-2 mb-2'>
                                    Good luck with your CUET PG English Literature preparation!
                                </p>
                            </div>

                            {/* <div className='col-md-5'> */}
                            <Image
                                src={`${process.env.PUBLIC_URL}/assets/online_pg/group-young-indian-students-sitting-auditorium-lecture-row-smiling_538002-3755.jpg`}
                                className="courseimg img-fluid"
                                rounded
                                alt="CTET"
                                style={{ objectFit: 'cover', width: '600px' }}
                            />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* </div > */}
            <Blog />
        </>

    )
}
