import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./cuetstyle.css";
import Card from "./Card.jsx";
import { useState, useEffect } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import SubscriptionCard from "../../Payment/SubscriptionCard.jsx";
import Cookies from "js-cookie";
import { Navigation } from "swiper";
import { Container, Row, Col, Form, Button, Image, Accordion } from "react-bootstrap";
// import documentLink from '../../../../public/assets/document/exampatternofCUET.docx';

const CUETCourse = ({ course, type }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      // console.log("tokenValue", Cookies.get("token"));
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);
  const [pack, setPack] = useState([]);
  const [title, setTitle] = useState("");
  const [packInc, setPackInc] = useState([]);
  const [packTitle, setPackTitle] = useState("");
  const [slideIndex, setSlideIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [boolaen, setBoolaen] = useState({
    alertTxt: false,
    modalValid: false,
    disDomain: false,
    showDomain: false,
    showLang: false,
    packAlrt: false,
  });
  const fallbackMessage = "Currently, No Subscription pack is available. ";
  const [show, setShow] = useState(false);
  const [packTemp, setPackTemp] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);
  const [checkout, setCheckout] = useState({});
  const [selectedCourse, setSelectedCourse] = useState("1");
  const isSmallScreen = window.innerWidth <= 900;

  const submitModal = (obj, checkVal, tempArr) => {
    tempArr = tempArr ? tempArr : packTemp;
    let countspecialInstruction = tempArr[0][obj].filter(
      (sub) => sub.specialInstruction === true
    ).length;
    if (countspecialInstruction >= tempArr[0][checkVal]) {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: false,
        modalValid: true,
      }));
    } else {
      setBoolaen((prev) => ({
        ...prev,
        alertTxt: true,
        modalValid: false,
      }));
    }
  };
  const breakpoints = {
    375: {
      slidesPerView: 1,
    },
    790: {
      slidesPerView: 1,
    },
    1150: {
      slidesPerView: 1,
    },
    1550: {
      slidesPerView: 1,
    },
    1900: {
      slidesPerView: 1,
    },
  };
  useEffect(() => {
    handleSubscriptionChange(selectedCourse);
  }, [selectedCourse]);
  const handleSubscriptionChange = (id) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/df/getAllSubscriptionPacks/${type === "UG" ? "1" : "9"}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          Authorization: `${Cookies.get("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setPack(data.Data);
        setIsPrimium((prev) => ({
          ...prev,
          msg: data.message,
          status: data.status,
        }));
        // const payBtn = document.querySelector(".payBtn");
        // payBtn.style.pointerEvents = "none";
        // payBtn.innerText = "Free";
      })
      .catch((e) => console.log("subscription 01 CUET", e));
  };
  const closeModal = () => {
    setPackInc([]);
    setBoolaen((prev) => ({
      ...prev,
      showDomain: false,
      showLang: false,
      modalValid: false,
    }));
  };
  const [isPrimium, setIsPrimium] = useState({ msg: "", status: 400 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [filterToggle, setFilterToggle] = useState(false);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Add event listener for the resize event
    window.addEventListener("resize", handleResize);
    // window.scrollTo(0, 0)
    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleClickSubscription = () => {
    window.location.href = "/online-subscription";
  }

  return (
    <div className={isSmallScreen ? "" : "cuetcontainer"} style={{ overflowX: "hidden" }}>
      <div className="cuethead">
        <h1>{course}</h1>
        <p>HOME/CUET({type}) COURSE</p>
      </div>
      {
        isSmallScreen ? (
          <Row>
            <Col md={6} sm={12} xs={12} className="faq_lay2 p-0">
              <div className="course_details_ctn">

                <h1>CUET({type}) Exam</h1>
                {type == "PG" ? <div className="cuetctn">
                  <p style={{ textAlign: "justify" }}>
                    Common University Entrance Test (CUET) is being introduced for
                    admission into all UG/PG programmes in all Central Universities
                    for academic sessions under the Ministry of Education (MoE). It
                    was introduced in 2022.
                  </p>
                </div>
                  : ""}

                <img
                  src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
                  className="courseimg"
                  alt="CUET"
                />
                <div className="textcontent">
                  <h4>Description</h4>
                  <p style={{ textAlign: "justify" }}>
                    {type == "PG" ? `Common University Entrance Test (CUET) is being introduced for admission
  into UG/PG programme in Central and participating Universities of the country.
  The Common University Entrance Test (CUET) will provide a common platform and
  equal opportunities to candidates across the country , especially those from North-East
  and from rural and other remote areas and help to establish better connect with
  the Universities. A single Examination will enable the candidates to cover a wide outreach and be part of
  the admission process to various Central Universities.` :
                      `Explore the best courses under CUET UG tailored for various streams. Find comprehensive insights
on top programs, eligibility criteria, and career prospects to make informed decisions for your
undergraduate studies `}
                  </p>
                </div>
                {type == "PG" ?
                  <div className="textcontent">
                    <h4>Eligibility</h4>
                    <p style={{ textAlign: "justify" }}>
                    </p>
                    For appearing in the CUET (PG) exam there is no age limit for the candidates. The candidates who have passed the bachelor degree/equivalent examination or appearing irrespective of their age can appear in the CUET (PG) examination. However, the candidates will be required to fulfil the age criteria of the University in which they are desirous of taking admission.
                  </div>
                  :
                  <div className="textcontent">
                    <h4>Introduction</h4>
                    <p style={{ textAlign: "justify" }}>
                    </p>
                    The Common University Entrance Test (CUET) UG has emerged as a significant pathway for
                    undergraduate admissions across various Indian universities. With its growing popularity, CUET
                    offers a wide range of courses catering to different academic streams, making it an essential exam
                    for aspiring students. This article aims to guide students in selecting the best CUET UG courses
                    based on their respective streams, ensuring they make informed decisions for their future.
                  </div>
                }

                {type == "PG" ?
                  (<div className="textcontent">
                    <h4>Mode Of Exam</h4>
                    <p style={{ textAlign: "justify" }}>
                      <strong style={{ color: 'black' }}>CUET (PG) will be conducted in Computer Based Test (CBT) mode only.</strong>
                    </p>
                  </div>) : (
                    <div className="textcontent">
                      <h4>Understanding CUET UG
                      </h4>
                      <p style={{ textAlign: "justify" }}>
                        CUET UG is an entrance examination conducted for undergraduate admissions in numerous
                        universities across India. The exam assesses candidates' knowledge through a structured format,
                        allowing universities to evaluate applicants fairly. The benefits of taking CUET UG include access
                        to prestigious institutions and diverse courses, enhancing students' academic prospects.

                      </p>
                      <p className="mt-1">The CUET exam pattern consists of multiple-choice questions (MCQs) covering various subjects,
                        while the CUET syllabus includes topics relevant to the chosen stream. Choosing the right subjects
                        is crucial, as it directly impacts admission opportunities. For detailed information on CUET,
                        students can refer to the [official NTA website]<a className="text-primary" href="https://www.nta.ac.in">(https://www.nta.ac.in).</a>
                      </p>
                    </div>
                  )}



                {type == "PG" ?
                  (
                    <div className="textcontent">
                      <h4>Medium of Question Papers</h4>
                      <p style={{ textAlign: "justify" }}>
                        The medium of Question Paper for CUET (PG) will be English and Hindi (Bilingual) except for languages, M.Tech Higher Sciences and Acharya papers (except Hindu studies, Baudha Darshan and Indian Knowledge System).
                      </p>
                    </div>
                  ) : (
                    <div className="text-content">
                      <h4>Best CUET UG Courses: Stream-Wise</h4>
                      <div>
                        <h5 className="mt-2 mb-2">A. Science</h5>
                        <p style={{ textAlign: "justify" }}>
                          For Science students, the top CUET UG courses include:
                        </p>
                        <div>
                          <li>B.Sc. Physics</li>
                          <li>B.Sc. Chemistry</li>
                          <li>B.Sc. Mathematics</li>
                          <li>B.Sc. Biology</li>
                          <li>BCA (Bachelor of Computer Applications)</li>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                          These courses offer promising career prospects in fields like research, healthcare, engineering, and
                          information technology. Notable universities offering these programs include Delhi University and
                          Banaras Hindu University.
                        </p>
                      </div>
                      <div>
                        <h5 className="mt-2 mb-2">B. Commerce</h5>
                        <p style={{ textAlign: "justify" }}>
                          Commerce students can opt for:
                        </p>
                        <div>
                          <li>B.Com (Bachelor of Commerce)</li>
                          <li>BBA (Bachelor of Business Administration)</li>
                          <li>BMS (Bachelor of Management Studies)</li>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                          These programs prepare students for careers in finance, management, and entrepreneurship. Top
                          colleges such as Delhi University and Shri Ram College of Commerce are renowned for their
                          commerce courses.
                        </p>
                      </div>
                      <div>
                        <h5 className="mt-2 mb-2">C. Arts/Humanities</h5>
                        <p style={{ textAlign: "justify" }}>
                          Arts and Humanities students have options like:
                        </p>
                        <div>
                          <li>BA English</li>
                          <li>BA History</li>
                          <li>BA Psychology</li>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                          These courses lead to diverse career paths in education, social work, and media. Leading
                          institutions like Delhi University and Jawaharlal Nehru University offer excellent programs in these
                          fields.
                        </p>
                      </div>
                      {/* <div>
                        <h5 className="mt-2 mb-2">D. Engineering</h5>
                        <p style={{ textAlign: "justify" }}>
                          Engineering aspirants can pursue:
                        </p>
                        <div>
                          <li>B.Tech in Computer Science Engineering (CSE)</li>
                          <li>B.Tech in Electronics and Communication Engineering (ECE)</li>
                          <li>B.Tech in Mechanical Engineering</li>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                          These degrees open doors to lucrative careers in technology and engineering sectors. Prestigious
                          institutions such as Delhi Technological University and Netaji Subhas University of Technology are
                          highly sought after for engineering studies.
                        </p>
                      </div> */}
                    </div>

                  )}
                {type == "PG" ? <></> : (
                  <div className="textcontent">
                    <div className="factors-to-consider">
                      <h4>Factors to Consider When Choosing a CUET UG Course</h4>
                      <p style={{ textAlign: "justify" }}>
                        Selecting the right CUET UG course involves careful consideration of various factors:
                      </p>
                      <>
                        <li>
                          <strong style={{ color: 'black' }}>Interests and Career Goals:</strong> Aligning course choices with personal interests and long-term career aspirations is vital.
                        </li>
                        <li>
                          <strong style={{ color: 'black' }}>Course Curriculum:</strong> Understanding the syllabus helps gauge if it matches your academic strengths.
                        </li>
                        <li>
                          <strong style={{ color: 'black' }}>Faculty Reputation:</strong> Researching faculty qualifications can provide insights into the quality of education.
                        </li>
                        <li>
                          <strong style={{ color: 'black' }}>College Reputation:</strong> Attending a well-regarded institution can enhance job prospects.
                        </li>
                        <li>
                          <strong style={{ color: 'black' }}>Placement Opportunities:</strong> Investigating placement records can help assess a college's effectiveness in securing jobs for graduates.
                        </li>
                      </>
                      <p style={{ textAlign: "justify" }}>
                        Students are encouraged to seek guidance from career counselors or mentors to make informed choices regarding their future.
                      </p>
                    </div>

                  </div>
                )
                }
                {type == "PG" ?
                  <div
                    className="modeofexamctn"
                    style={{
                      padding: "1rem 2rem",
                      width: "100%",
                      background: "#71269C44",
                      borderRadius: "4px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h4>{type == "PG" ? "Marking Scheme" : ""}</h4>
                    <div
                      className="lictn"
                      style={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {type == "PG" ? (<div>
                        <li> - Each question carries 04 (four) marks.</li>
                        <li> - For each correct response, candidate will get 04 (four) marks.</li>
                        <li> - For each incorrect response, 01(one) mark will be deducted from the total score.</li>
                        <li> - Un-answered/un-attempted response will be given no marks.</li>
                      </div>) : (
                        // <div>
                        //   <li> - For each correct answer, five (5) marks will be rewarded and for each wrong answer -1 will be deducted. There is no mark for unattempt questions.</li>
                        // </div>
                        ''
                      )
                      }
                    </div>
                  </div>
                  :
                  <div className="conclusion">
                    <h4>Conclusion</h4>
                    <p style={{ textAlign: "justify" }}>
                      In conclusion, selecting the right CUET UG course is critical for academic success and future career opportunities.
                      Students should carefully evaluate their options and consider factors such as interests, college reputation, and
                      placement opportunities to make informed decisions. The CUET UG opens doors to prestigious institutions and
                      rewarding careers, making it an essential step in higher education.
                    </p>
                  </div>
                }


                {type == "PG" ? (<div
                  className="modeofexamctn"
                  style={{
                    padding: "1rem 2rem",
                    width: "100%",
                    background: "#71269C44",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h4>Syllabus of CUET (PG) 2025</h4>
                  <div
                    className="lictn"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div>
                      <li> - Entrance syllabus for question papers would be available in the official website <a href="https://pgcuet.samarth.ac.in"><span style={{ color: "#71269C" }}>https://pgcuet.samarth.ac.in</span></a> </li>
                    </div>
                  </div>
                </div>) : (
                  // <div
                  //   className="modeofexamctn"
                  //   style={{
                  //     padding: "1rem 2rem",
                  //     width: "100%",
                  //     background: "#71269C44",
                  //     borderRadius: "4px",
                  //     display: "flex",
                  //     flexDirection: "column",
                  //   }}
                  // >
                  //   <h4>Syllabus of CUET (UG) 2024</h4>
                  //   <div
                  //     className="lictn"
                  //     style={{ display: "flex", flexWrap: "wrap" }}
                  //   >
                  //     <div>
                  //       <li> - The syllabus for subjects is available on the NTA website <a href="https://exams.nta.ac.in/CUET-UG/"><span style={{ color: "#71269C" }}> https://exams.nta.ac.in/CUET-UG/.</span> </a></li>
                  //     </div>
                  //   </div>
                  // </div>
                  ""
                )}
                {type == "PG" ? (<div className="table-responsive-sm">
                  <h4>Schedule of Examination </h4>
                  {/*
                  <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                    <tbody>
                      {/* <tr>
      <td colSpan="4"></td>
      <td colSpan="4"></td>
    </tr> 
                      <tr>
                        <td style={{ width: '290px' }}>Tentative Dates of Examination:</td>
                        <td>10-31<sup>st</sup> March 2025</td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td colSpan="4">NOTE:  CUET (PG) 2025 exam schedule will be updated as
                          soon as NTA releases the dates &amp; slot timings.</td>
                      </tr>
                       <tr>
      
    </tr>
    <tr>
      <td>Mode of Examination </td>
      <td colSpan="3"> Computer Based Test (CBT) mode only</td>
    </tr>
    <tr>
      <th scope="col">Shift</th>
      <th scope="col">Shift-1</th>
      <th scope="col">Shift-2</th>
      <th scope="col">Shift-3</th>
    </tr>
    <tr>
      <td>Timing of Examination</td>
      <td>9.00 a.m to 10.45 a.m</td>
      <td>12.45 p.m to 2.30 p.m</td>
      <td>4.30 p.m to 6.15 p.m</td>
    </tr>
    <tr>
      <td>Duration of Examination </td>
      <td>1 Hour and 45 minutes (105 Minutes) </td>
      <td>1 Hour and 45 minutes (105 Minutes) </td>
      <td>1 Hour and 45 minutes  (105 Minutes) </td>
    </tr>
    <tr>
      <td>Total number of Questions </td>
      <td colSpan="3">The question paper will have 75 questions. </td>
    </tr>
    <tr>
      <td colSpan="4">Note:  Compensatory time for PwBD candidates of 20 minutes for every one-hour examination.

        <br></br>NOTE:  CUET (PG) 2025 exam schedule will be updated as
        soon as NTA releases the dates &amp; slot timings.</td>
    </tr>
                    </tbody>
                  </table>
                  */}

                  <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                    <tbody>

                      <tr>
                        <td >Online Submission of Application Form</td>
                        <td>
                          02 January to 01 February 2025
                          (Up to 11:50 P.M.)</td>
                        <td></td>
                        <td></td>
                      </tr>


                      <tr>
                        <td>Last date of successful transactions of fee through Credit / Debit Card / Net-Banking / UPI</td>
                        <td > 02 February 2025
                          (Up to  11:50 P.M.)
                        </td>
                      </tr>
                      <tr>
                        <td>Correction in Particulars</td>
                        <td >             03 February to 05 February 2025
                          (Up to 11:50  P.M.)

                        </td>
                      </tr>
                      <tr>
                        <td>Announcement of the City of Examination</td>
                        <td >                First Week of the March 2025
                        </td>
                      </tr>
                      <tr>
                        <td>Downloading Admit Cards from NTA website</td>
                        <td >             04 days before the actual date of the
                          examination

                        </td>
                      </tr>
                      <tr>
                        <td>Date of Examination</td>
                        <td >        Between 13 March 2025 and 31 March
                          2025

                        </td>
                      </tr>
                      <tr>
                        <td>Display of the Recorded Responses and Answer Keys</td>
                        <td >       To be announced later on the website
                        </td>
                      </tr>
                      <tr>
                        <td>Official Website(s)</td>
                        <td ><a className="text-primary" href="www.nta.ac.in">
                          www.nta.ac.in
                        </a>
                          <br></br><a className="text-primary" href="https://exams.nta.ac.in/CUET-PG/">
                            https://exams.nta.ac.in/CUET-PG/

                          </a>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>) : (
                  // <div className="table-responsive-sm">
                  //   <h4>Schedule of Examination</h4>
                  //   <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                  //     <tbody>
                  //       <tr>
                  //         <td>Dates of Examination </td>
                  //         <td>Between 15 May 2024 & 31 May 2024 (Dates may vary
                  //           depending on the National Election Schedule)</td>
                  //       </tr>
                  //       <tr>
                  //         <td>Pattern of Question Paper </td>
                  //         <td>Objective type Multiple Choice Questions (MCQs)</td>
                  //       </tr>
                  //       <tr>
                  //         <td>Total number of Questions </td>
                  //         <td>40 out of 50 questions for all test papers & 50 out of 60
                  //           questions for General Test</td>
                  //       </tr>
                  //       <tr>
                  //         <td>Duration of Examination </td>
                  //         <td>45 minutes for all test papers except
                  //           Mathematics/Applied
                  //           Mathematics,Accountancy,Physics,Chemistry,Economics
                  //           ,Computer Science/Informatics Practices,& General Test
                  //           which would be 60 minutes.</td>
                  //       </tr>
                  //       <tr>
                  //         <td>Slots</td>
                  //         <td>The examination will be conducted on multiple days in two
                  //           or three shifts per day, depending on the number of
                  //           candidates & their combinations.</td>
                  //       </tr>
                  //       <tr>
                  //         <td colSpan="2">NOTE: Compensatory time for PwBD Candidates of 20 minutes for each hour examination will be
                  //           given.</td>
                  //       </tr>
                  //     </tbody>
                  //   </table>
                  // </div>
                  ''

                )}
                {type == "PG" ? '' :
                  <div className="blog-faq w-100">
                    <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                      <div>
                      {/* FAQs on CUET UG History Question Paper */}
                        <h3 className="hthree_size">FAQS on CUET UG
                        </h3>
                      </div>
                    </Col>

                    <Col md={12} sm={12} xs={12} className="faq_margin">

                      <Accordion
                        data-responsive-accordion-tabs="-medium-tabs large-tabs"

                        className="w-100"
                      >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                          <Accordion.Header className="accordion_header w-100">
                            How many universities are participating in CUET?
                          </Accordion.Header>
                          <Accordion.Body>Over 40 universities are currently participating in the CUET examination process.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                          <Accordion.Header className="accordion_header w-100">
                            Which is the best college under CUET?
                          </Accordion.Header>
                          <Accordion.Body>
                            Colleges like Delhi University and Jawaharlal Nehru University are often regarded as among the
                            best under the CUET framework.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                          <Accordion.Header className="accordion_header w-100">
                            What is a good score in CUET UG?
                          </Accordion.Header>
                          <Accordion.Body>A score around 200 out of 300 is typically considered competitive for top colleges.</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                        <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                          <Accordion.Header className="accordion_header w-100">
                            How many domain subjects are compulsory in CUET for DU?
                          </Accordion.Header>
                          <Accordion.Body>For Delhi University, candidates must choose at least two domain-specific subjects along with a
                            language paper.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </div>
                }
                {type == "PG" ? (<div className="textcontent">
                  <h4>Structure of Question Papers for CUET(PG) 2025:
                  </h4>
                  <div>
                    <li>- Candidates can choose maximum of 4 (four) question paper codes.</li>
                    <li>- There would be no General Test with each paper.</li>
                    <li>- The candidate can choose General Test paper as a subject. The comprehension part of these papers would be English or Hindi as chosen by the candidate during registration.
                    </li>
                  </div>
                </div>) : (
                  // <div
                  //   className="modeofexamctn"
                  //   style={{
                  //     padding: "1rem 2rem",
                  //     width: "100%",
                  //     background: "#71269C44",
                  //     borderRadius: "4px",
                  //     display: "flex",
                  //     flexDirection: "column",
                  //   }}
                  // >
                  //   <div className="d-flex align-items-center">
                  //     <h4>Please click here to know more</h4>
                  //     <a href={"../../../../public/assets/document/exampatternofCUET.docx"} target="_blank" download={"exampatternofCUET.docx"}>&nbsp; - Open Document</a>
                  //   </div>

                  // </div>
                  <>
                  </>
                )}
              </div>
            </Col>
            <Col md={6} sm={12} xs={12} className="faq_lay2 pt-4">
              <div>
                {type = "UG" ? <>
                  <div className="blog-bg mb-3 p-3" style={{ height: "auto", borderRadius: "10px", width: "100%" }}>
                    <p style={{ textAlign: "left" }}>Unlock boundless knowledge from the comfort of your home with our online classes subscription – where learning knows no limits!</p>
                    <button type="button" className="sub_button1 btn mt-2 " onClick={handleClickSubscription}>
                      CUET Online Subscription
                    </button>
                  </div>
                </> : <>
                  <div>
                    {pack && pack.length !== 0 ? (
                      <Swiper
                        allowTouchMove={false}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation={true}
                        modules={[Navigation]}
                        onSlideChange={() => {
                          setSlideIndex(slideIndex + 1);
                        }}
                        className="custom-slider"
                        id="course-xyz-page"
                      >
                        {pack?.map((item, index) => {
                          let domainCount1 = item.avlDomainSubjects?.filter(
                            (x) => x.specialInstruction === true
                          ).length;
                          let langCount1 = item.avlLangSubjects?.filter(
                            (x) => x.specialInstruction === true
                          ).length;
                          let selLang = item.avlLangSubjects?.filter(
                            (x) => x.selection === true
                          ).length;
                          let selDomain = item.avlDomainSubjects?.filter(
                            (x) => x.selection === true
                          ).length;

                          let domainSub1 = [];
                          item.avlDomainSubjects?.filter((x) => {
                            if (x.specialInstruction || x.selection) {
                              domainSub1.push(x.topicName);
                              return x.topicName;
                            }
                          });

                          return (
                            <SwiperSlide key={index}>
                              <SubscriptionCard
                                item={item}
                                domainCount1={domainCount1}
                                langCount1={langCount1}
                                selLang={selLang}
                                selDomain={selDomain}
                                domainSub1={domainSub1}
                                setPackTitle={setPackTitle}
                                setTitle={setTitle}
                                setPackInc={setPackInc}
                                setPage={setPage}
                                setBoolaen={setBoolaen}
                                submitModal={submitModal}
                                setCheckout={setCheckout}
                                setCouponDetails={setCouponDetails}
                                setShow={setShow}
                                closeModal={closeModal}
                                pack={pack}
                                setPackTemp={setPackTemp}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <div className="fallback-message-subscriptions-container">
                        <p>{fallbackMessage}</p>
                        {/* <p>Test From Ravi Sharma</p> */}
                      </div>
                    )}
                  </div>
                </>}

                {/* <div className="p-4">
                  <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
                  <ul style={{ fontSize: "14px" }}>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise practice paper</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Subject wise mock papers</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Mock exam(replica of NTA interface)</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live classes by experts</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Study materials & previous year’s papers pdf</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Mock registration process</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Delhi University form fill - up Process</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Live Quiz</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Self - performance analysis</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Individual Ranking</span></small></strong></li>
                    <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px", color: 'black' }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1">Doubt resolution</span></small></strong></li>
                  </ul>
                </div> */}
                {/* <hr className="bg-secondary " />
                <div className="p-4">
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaStar style={{ color: "#FFC107" }} />
                  <FaRegStar style={{ color: "#FFC107" }} />
                  <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
                </div> */}
              </div>
            </Col>
          </Row>
        ) : (
          <div className="cuetcontent">
            <div className="course_details_ctn">

              <h1>CUET({type}) Exam</h1>
              {type == "PG" ? <div className="cuetctn">
                <p style={{ textAlign: "justify" }}>
                  Common University Entrance Test (CUET) is being introduced for
                  admission into all UG/PG programmes in all Central Universities
                  for academic sessions under the Ministry of Education (MoE). It
                  was introduced in 2022.
                </p>
              </div>
                : ""}

              <img
                src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
                className="courseimg"
                alt="CUET"
              />
              <div className="textcontent">
                <h4>Description</h4>
                <p style={{ textAlign: "justify" }}>
                  {type == "PG" ? `Common University Entrance Test (CUET) is being introduced for admission
                into UG/PG programme in Central and participating Universities of the country.
                The Common University Entrance Test (CUET) will provide a common platform and
                equal opportunities to candidates across the country , especially those from North-East
                and from rural and other remote areas and help to establish better connect with
                the Universities. A single Examination will enable the candidates to cover a wide outreach and be part of
                the admission process to various Central Universities.` :
                    `Explore the best courses under CUET UG tailored for various streams. Find comprehensive insights
on top programs, eligibility criteria, and career prospects to make informed decisions for your
undergraduate studies `}
                </p>
              </div>
              {type == "PG" ?
                <div className="textcontent">
                  <h4>Eligibility</h4>
                  <p style={{ textAlign: "justify" }}>
                  </p>
                  For appearing in the CUET (PG) exam there is no age limit for the candidates. The candidates who have passed the bachelor degree/equivalent examination or appearing irrespective of their age can appear in the CUET (PG) examination. However, the candidates will be required to fulfil the age criteria of the University in which they are desirous of taking admission.
                </div>
                :
                <div className="textcontent">
                  <h4>Introduction</h4>
                  <p style={{ textAlign: "justify" }}>
                  </p>
                  The Common University Entrance Test (CUET) UG has emerged as a significant pathway for
                  undergraduate admissions across various Indian universities. With its growing popularity, CUET
                  offers a wide range of courses catering to different academic streams, making it an essential exam
                  for aspiring students. This article aims to guide students in selecting the best CUET UG courses
                  based on their respective streams, ensuring they make informed decisions for their future.
                </div>
              }

              {type == "PG" ?
                (<div className="textcontent">
                  <h4>Mode Of Exam</h4>
                  <p style={{ textAlign: "justify" }}>
                    <strong style={{ color: 'black' }}>CUET (PG) will be conducted in Computer Based Test (CBT) mode only.</strong>
                  </p>
                </div>) : (
                  <div className="textcontent">
                    <h4>Understanding CUET UG
                    </h4>
                    <p style={{ textAlign: "justify" }}>
                      CUET UG is an entrance examination conducted for undergraduate admissions in numerous
                      universities across India. The exam assesses candidates' knowledge through a structured format,
                      allowing universities to evaluate applicants fairly. The benefits of taking CUET UG include access
                      to prestigious institutions and diverse courses, enhancing students' academic prospects.

                    </p>
                    <p className="mt-1">The CUET exam pattern consists of multiple-choice questions (MCQs) covering various subjects,
                      while the CUET syllabus includes topics relevant to the chosen stream. Choosing the right subjects
                      is crucial, as it directly impacts admission opportunities. For detailed information on CUET,
                      students can refer to the [official NTA website]<a className="text-primary" href="https://www.nta.ac.in">(https://www.nta.ac.in).</a>
                    </p>
                  </div>
                )}



              {type == "PG" ?
                (
                  <div className="textcontent">
                    <h4>Medium of Question Papers</h4>
                    <p style={{ textAlign: "justify" }}>
                      The medium of Question Paper for CUET (PG) will be English and Hindi (Bilingual) except for languages, M.Tech Higher Sciences and Acharya papers (except Hindu studies, Baudha Darshan and Indian Knowledge System).
                    </p>
                  </div>
                ) : (
                  <div className="text-content">
                    <h4>Best CUET UG Courses: Stream-Wise</h4>
                    <div>
                      <h5 className="mt-2 mb-2">A. Science</h5>
                      <p style={{ textAlign: "justify" }}>
                        For Science students, the top CUET UG courses include:
                      </p>
                      <div>
                        <li>B.Sc. Physics</li>
                        <li>B.Sc. Chemistry</li>
                        <li>B.Sc. Mathematics</li>
                        <li>B.Sc. Biology</li>
                        <li>BCA (Bachelor of Computer Applications)</li>
                      </div>
                      <p style={{ textAlign: "justify" }}>
                        These courses offer promising career prospects in fields like research, healthcare, engineering, and
                        information technology. Notable universities offering these programs include Delhi University and
                        Banaras Hindu University.
                      </p>
                    </div>
                    <div>
                      <h5 className="mt-2 mb-2">B. Commerce</h5>
                      <p style={{ textAlign: "justify" }}>
                        Commerce students can opt for:
                      </p>
                      <div>
                        <li>B.Com (Bachelor of Commerce)</li>
                        <li>BBA (Bachelor of Business Administration)</li>
                        <li>BMS (Bachelor of Management Studies)</li>
                      </div>
                      <p style={{ textAlign: "justify" }}>
                        These programs prepare students for careers in finance, management, and entrepreneurship. Top
                        colleges such as Delhi University and Shri Ram College of Commerce are renowned for their
                        commerce courses.
                      </p>
                    </div>
                    <div>
                      <h5 className="mt-2 mb-2">C. Arts/Humanities</h5>
                      <p style={{ textAlign: "justify" }}>
                        Arts and Humanities students have options like:
                      </p>
                      <div>
                        <li>BA English</li>
                        <li>BA History</li>
                        <li>BA Psychology</li>
                      </div>
                      <p style={{ textAlign: "justify" }}>
                        These courses lead to diverse career paths in education, social work, and media. Leading
                        institutions like Delhi University and Jawaharlal Nehru University offer excellent programs in these
                        fields.
                      </p>
                    </div>
                    {/* <div>
                      <h5 className="mt-2 mb-2">D. Engineering</h5>
                      <p style={{ textAlign: "justify" }}>
                        Engineering aspirants can pursue:
                      </p>
                      <div>
                        <li>B.Tech in Computer Science Engineering (CSE)</li>
                        <li>B.Tech in Electronics and Communication Engineering (ECE)</li>
                        <li>B.Tech in Mechanical Engineering</li>
                      </div>
                      <p style={{ textAlign: "justify" }}>
                        These degrees open doors to lucrative careers in technology and engineering sectors. Prestigious
                        institutions such as Delhi Technological University and Netaji Subhas University of Technology are
                        highly sought after for engineering studies.
                      </p>
                    </div> */}
                  </div>

                )}
              {type == "PG" ? <></> : (
                <div className="textcontent">
                  <div className="factors-to-consider">
                    <h4>Factors to Consider When Choosing a CUET UG Course</h4>
                    <p style={{ textAlign: "justify" }}>
                      Selecting the right CUET UG course involves careful consideration of various factors:
                    </p>
                    <>
                      <li>
                        <strong style={{ color: 'black' }}>Interests and Career Goals:</strong> Aligning course choices with personal interests and long-term career aspirations is vital.
                      </li>
                      <li>
                        <strong style={{ color: 'black' }}>Course Curriculum:</strong> Understanding the syllabus helps gauge if it matches your academic strengths.
                      </li>
                      <li>
                        <strong style={{ color: 'black' }}>Faculty Reputation:</strong> Researching faculty qualifications can provide insights into the quality of education.
                      </li>
                      <li>
                        <strong style={{ color: 'black' }}>College Reputation:</strong> Attending a well-regarded institution can enhance job prospects.
                      </li>
                      <li>
                        <strong style={{ color: 'black' }}>Placement Opportunities:</strong> Investigating placement records can help assess a college's effectiveness in securing jobs for graduates.
                      </li>
                    </>
                    <p style={{ textAlign: "justify" }}>
                      Students are encouraged to seek guidance from career counselors or mentors to make informed choices regarding their future.
                    </p>
                  </div>

                </div>
              )
              }
              {type == "PG" ?
                <div
                  className="modeofexamctn"
                  style={{
                    padding: "1rem 2rem",
                    width: "100%",
                    background: "#71269C44",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h4>{type == "PG" ? "Marking Scheme" : ""}</h4>
                  <div
                    className="lictn"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    {type == "PG" ? (<div>
                      <li> - Each question carries 04 (four) marks.</li>
                      <li> - For each correct response, candidate will get 04 (four) marks.</li>
                      <li> - For each incorrect response, 01(one) mark will be deducted from the total score.</li>
                      <li> - Un-answered/un-attempted response will be given no marks.</li>
                    </div>) : (
                      // <div>
                      //   <li> - For each correct answer, five (5) marks will be rewarded and for each wrong answer -1 will be deducted. There is no mark for unattempt questions.</li>
                      // </div>
                      ''
                    )
                    }
                  </div>
                </div>
                :
                <div className="conclusion">
                  <h4>Conclusion</h4>
                  <p style={{ textAlign: "justify" }}>
                    In conclusion, selecting the right CUET UG course is critical for academic success and future career opportunities.
                    Students should carefully evaluate their options and consider factors such as interests, college reputation, and
                    placement opportunities to make informed decisions. The CUET UG opens doors to prestigious institutions and
                    rewarding careers, making it an essential step in higher education.
                  </p>
                </div>
              }


              {type == "PG" ? (<div
                className="modeofexamctn"
                style={{
                  padding: "1rem 2rem",
                  width: "100%",
                  background: "#71269C44",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h4>Syllabus of CUET (PG) 2025</h4>
                <div
                  className="lictn"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div>
                    <li> - Entrance syllabus for question papers would be available in the official website <a href="https://pgcuet.samarth.ac.in"><span style={{ color: "#71269C" }}>https://pgcuet.samarth.ac.in</span></a> </li>
                  </div>
                </div>
              </div>) : (
                // <div
                //   className="modeofexamctn"
                //   style={{
                //     padding: "1rem 2rem",
                //     width: "100%",
                //     background: "#71269C44",
                //     borderRadius: "4px",
                //     display: "flex",
                //     flexDirection: "column",
                //   }}
                // >
                //   <h4>Syllabus of CUET (UG) 2024</h4>
                //   <div
                //     className="lictn"
                //     style={{ display: "flex", flexWrap: "wrap" }}
                //   >
                //     <div>
                //       <li> - The syllabus for subjects is available on the NTA website <a href="https://exams.nta.ac.in/CUET-UG/"><span style={{ color: "#71269C" }}> https://exams.nta.ac.in/CUET-UG/.</span> </a></li>
                //     </div>
                //   </div>
                // </div>
                ""
              )}
              {type == "PG" ? (<div className="table-responsive-sm">
                <h4>Schedule of Examination </h4>
                {/*
                <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                  <tbody>
                     <tr>
                    <td colSpan="4"></td>
                    <td colSpan="4"></td>
                  </tr> 
                    <tr>
                      <td style={{ width: '290px' }}>Tentative Dates of Examination:</td>
                      <td>10-31<sup>st</sup> March 2025</td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan="4">NOTE:  CUET (PG) 2025 exam schedule will be updated as
                        soon as NTA releases the dates &amp; slot timings.</td>
                    </tr>
                    {/* <tr>
                    
                  </tr>
                  <tr>
                    <td>Mode of Examination </td>
                    <td colSpan="3"> Computer Based Test (CBT) mode only</td>
                  </tr>
                  <tr>
                    <th scope="col">Shift</th>
                    <th scope="col">Shift-1</th>
                    <th scope="col">Shift-2</th>
                    <th scope="col">Shift-3</th>
                  </tr>
                  <tr>
                    <td>Timing of Examination</td>
                    <td>9.00 a.m to 10.45 a.m</td>
                    <td>12.45 p.m to 2.30 p.m</td>
                    <td>4.30 p.m to 6.15 p.m</td>
                  </tr>
                  <tr>
                    <td>Duration of Examination </td>
                    <td>1 Hour and 45 minutes (105 Minutes) </td>
                    <td>1 Hour and 45 minutes (105 Minutes) </td>
                    <td>1 Hour and 45 minutes  (105 Minutes) </td>
                  </tr>
                  <tr>
                    <td>Total number of Questions </td>
                    <td colSpan="3">The question paper will have 75 questions. </td>
                  </tr>
                  <tr>
                    <td colSpan="4">Note:  Compensatory time for PwBD candidates of 20 minutes for every one-hour examination.

                      <br></br>NOTE:  CUET (PG) 2025 exam schedule will be updated as
                      soon as NTA releases the dates &amp; slot timings.</td>
                  </tr>
                  </tbody>
                </table>
                   */}

                <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                  <tbody>

                    <tr>
                      <td >Online Submission of Application Form</td>
                      <td>
                        02 January to 01 February 2025
                        (Up to 11:50 P.M.)</td>
                      <td></td>
                      <td></td>
                    </tr>


                    <tr>
                      <td>Last date of successful transactions of fee through Credit / Debit Card / Net-Banking / UPI</td>
                      <td > 02 February 2025
                        (Up to  11:50 P.M.)
                      </td>
                    </tr>
                    <tr>
                      <td>Correction in Particulars</td>
                      <td >             03 February to 05 February 2025
                        (Up to 11:50  P.M.)

                      </td>
                    </tr>
                    <tr>
                      <td>Announcement of the City of Examination</td>
                      <td >                First Week of the March 2025
                      </td>
                    </tr>
                    <tr>
                      <td>Downloading Admit Cards from NTA website</td>
                      <td >             04 days before the actual date of the
                        examination

                      </td>
                    </tr>
                    <tr>
                      <td>Date of Examination</td>
                      <td >        Between 13 March 2025 and 31 March
                        2025

                      </td>
                    </tr>
                    <tr>
                      <td>Display of the Recorded Responses and Answer Keys</td>
                      <td >       To be announced later on the website
                      </td>
                    </tr>
                    <tr>
                      <td>Official Website(s)</td>
                      <td ><a className="text-primary" href="www.nta.ac.in">
                        www.nta.ac.in
                      </a>
                        <br></br><a className="text-primary" href="https://exams.nta.ac.in/CUET-PG/">
                          https://exams.nta.ac.in/CUET-PG/

                        </a>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>) : (
                // <div className="table-responsive-sm">
                //   <h4>Schedule of Examination</h4>
                //   <table className="table table-bordered table-light" style={{ textAlign: "left" }}>
                //     <tbody>
                //       <tr>
                //         <td>Dates of Examination </td>
                //         <td>Between 15 May 2024 & 31 May 2024 (Dates may vary
                //           depending on the National Election Schedule)</td>
                //       </tr>
                //       <tr>
                //         <td>Pattern of Question Paper </td>
                //         <td>Objective type Multiple Choice Questions (MCQs)</td>
                //       </tr>
                //       <tr>
                //         <td>Total number of Questions </td>
                //         <td>40 out of 50 questions for all test papers & 50 out of 60
                //           questions for General Test</td>
                //       </tr>
                //       <tr>
                //         <td>Duration of Examination </td>
                //         <td>45 minutes for all test papers except
                //           Mathematics/Applied
                //           Mathematics,Accountancy,Physics,Chemistry,Economics
                //           ,Computer Science/Informatics Practices,& General Test
                //           which would be 60 minutes.</td>
                //       </tr>
                //       <tr>
                //         <td>Slots</td>
                //         <td>The examination will be conducted on multiple days in two
                //           or three shifts per day, depending on the number of
                //           candidates & their combinations.</td>
                //       </tr>
                //       <tr>
                //         <td colSpan="2">NOTE: Compensatory time for PwBD Candidates of 20 minutes for each hour examination will be
                //           given.</td>
                //       </tr>
                //     </tbody>
                //   </table>
                // </div>
                ''

              )}
              {type == "PG" ? '' :
                <div className="blog-faq w-100 ">
                  <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                    <div>
                      <h2 className="hthree_size">FAQS on CUET UG
                      </h2>
                    </div>
                  </Col>

                  <Col md={12} sm={12} xs={12} className="faq_margin">

                    <Accordion
                      data-responsive-accordion-tabs="-medium-tabs large-tabs"

                      className="w-100"
                    >
                      <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                        <Accordion.Header className="accordion_header w-100">
                          How many universities are participating in CUET?
                        </Accordion.Header>
                        <Accordion.Body>Over 40 universities are currently participating in the CUET examination process.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                      <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                        <Accordion.Header className="accordion_header w-100">
                          Which is the best college under CUET?
                        </Accordion.Header>
                        <Accordion.Body>
                          Colleges like Delhi University and Jawaharlal Nehru University are often regarded as among the
                          best under the CUET framework.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                      <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                        <Accordion.Header className="accordion_header w-100">
                          What is a good score in CUET UG?
                        </Accordion.Header>
                        <Accordion.Body>A score around 200 out of 300 is typically considered competitive for top colleges.</Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                  >
                      <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                        <Accordion.Header className="accordion_header w-100">
                          How many domain subjects are compulsory in CUET for DU?
                        </Accordion.Header>
                        <Accordion.Body>For Delhi University, candidates must choose at least two domain-specific subjects along with a
                          language paper.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </div>
              }
              {type == "PG" ? (<div className="textcontent">
                <h4>Structure of Question Papers for CUET(PG) 2025:
                </h4>
                <div>
                  <li>- Candidates can choose maximum of 4 (four) question paper codes.</li>
                  <li>- There would be no General Test with each paper.</li>
                  <li>- The candidate can choose General Test paper as a subject. The comprehension part of these papers would be English or Hindi as chosen by the candidate during registration.
                  </li>
                </div>
              </div>) : (
                // <div
                //   className="modeofexamctn"
                //   style={{
                //     padding: "1rem 2rem",
                //     width: "100%",
                //     background: "#71269C44",
                //     borderRadius: "4px",
                //     display: "flex",
                //     flexDirection: "column",
                //   }}
                // >
                //   <div className="d-flex align-items-center">
                //     <h4>Please click here to know more</h4>
                //     <a href={"../../../../public/assets/document/exampatternofCUET.docx"} target="_blank" download={"exampatternofCUET.docx"}>&nbsp; - Open Document</a>
                //   </div>

                // </div>
                <>
                </>
              )}
            </div>
            <div style={{ maxWidth: "30%" }}>
              {type = "UG" ? <>
                <div className="blog-bg mb-3 p-3" style={{ height: "auto", borderRadius: "10px", width: "100%" }}>
                  <p style={{ textAlign: "left" }}>Unlock boundless knowledge from the comfort of your home with our online classes subscription – where learning knows no limits!</p>
                  <button type="button" className="sub_button1 btn mt-2 " onClick={handleClickSubscription}>
                    CUET Online Subscription
                  </button>
                </div>
              </> : <>
                <div>
                  {pack && pack.length !== 0 ? (
                    <Swiper
                      allowTouchMove={false}
                      spaceBetween={0}
                      slidesPerView={1}
                      navigation={true}
                      modules={[Navigation]}
                      onSlideChange={() => {
                        setSlideIndex(slideIndex + 1);
                      }}
                      className="custom-slider"
                      id="course-xyz-page"
                    >
                      {pack?.map((item, index) => {
                        let domainCount1 = item.avlDomainSubjects?.filter(
                          (x) => x.specialInstruction === true
                        ).length;
                        let langCount1 = item.avlLangSubjects?.filter(
                          (x) => x.specialInstruction === true
                        ).length;
                        let selLang = item.avlLangSubjects?.filter(
                          (x) => x.selection === true
                        ).length;
                        let selDomain = item.avlDomainSubjects?.filter(
                          (x) => x.selection === true
                        ).length;

                        let domainSub1 = [];
                        item.avlDomainSubjects?.filter((x) => {
                          if (x.specialInstruction || x.selection) {
                            domainSub1.push(x.topicName);
                            return x.topicName;
                          }
                        });

                        return (
                          <SwiperSlide key={index}>
                            <SubscriptionCard
                              item={item}
                              domainCount1={domainCount1}
                              langCount1={langCount1}
                              selLang={selLang}
                              selDomain={selDomain}
                              domainSub1={domainSub1}
                              setPackTitle={setPackTitle}
                              setTitle={setTitle}
                              setPackInc={setPackInc}
                              setPage={setPage}
                              setBoolaen={setBoolaen}
                              submitModal={submitModal}
                              setCheckout={setCheckout}
                              setCouponDetails={setCouponDetails}
                              setShow={setShow}
                              closeModal={closeModal}
                              pack={pack}
                              setPackTemp={setPackTemp}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  ) : (
                    <div className="fallback-message-subscriptions-container">
                      <p>{fallbackMessage}</p>
                      {/* <p>Test From Ravi Sharma</p> */}
                    </div>
                  )}
                </div>
              </>}
              {/* <div>
              <h4 style={{ margin: "20px 0px 10px 0px" }}>This course included : </h4>
              <ul style={{ fontSize: "14px" }}>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Subject wise practice paper</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Subject wise mock papers</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Mock exam(replica of NTA interface)</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Live classes by experts</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Study materials & previous year’s papers pdf</span></small></strong></li>
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Mock registration process</span></small></strong></li >
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Delhi University form fill - up Process</span></small></strong></li >
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Live Quiz</span></small></strong></li >
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Self - performance analysis</span></small></strong></li >
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Individual Ranking</span></small></strong></li >
                <li className="mb-1"><strong ><small className="d-flex flex-row"><span style={{ width: "15px" }}><img src={`${process.env.PUBLIC_URL}/assets/Tick.png`} height="15px" width="15px" /></span> <span className="ml-1" style={{ color: 'black' }}>Doubt resolution</span></small></strong></li >
              </ul >
            </div > */}
              {/* <hr className="bg-secondary" />
            <div>
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaStar style={{ color: "#FFC107" }} />
              <FaRegStar style={{ color: "#FFC107" }} />
              <span style={{ marginLeft: "0.5rem" }}>Rating: 4.0</span>
            </div> */}
            </div >
          </div >
        )
      }
    </div >
  );
};

export default CUETCourse;
