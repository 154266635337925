import React from "react";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Row, Col, Card, Image, Accordion } from "react-bootstrap";
import Blog from "../../Components/Copy/Blog/Blog";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'

export default function Blog6() {

    const navigate = useNavigate()

    return (
        <div>
            <>
                <Helmet>
                    <meta charSet='utf-8' />
                    <meta
                        name='description'
                        content='Prepare for CUET PG 2025 with our complete guide! Get details on exam dates, eligibility, syllabus, and expert resources to ace your postgraduate entrance exam'
                    />
                    <title>CUET PG 2025 – Comprehensive Guide to Exam Dates, Eligibility & Preparation</title>
                </Helmet>

                <div className="blog-bg container-fluid mb-5">
                    <div className="Contact_content">Blogs</div>
                    <div className="Contact_slashContent">Home / Blogs / CUET PG 2025: Your Comprehensive Guide to Success</div>
                </div>
                <div className="row">

                    <div className="col-md-7 col-lg-7 col-sm-11 p-5 ms-2 me-3 ms-sm-4" >

                        <div className="author mt-3">
                            <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                            <div className="author-name">By Chinmoy Mohan</div> |
                            <div>Published on December 23, 2024</div> | <div>2min read</div>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/online_pg/pexels-ketut-subiyanto-4623355.jpg`} className="w-100 h-60 mt-3" alt="author" style={{ objectFit: 'cover' }} />
                        <div className="mt-3">
                            <h1 className="hone_size">CUET PG 2025: Your Comprehensive Guide to Success</h1>
                            <p style={{ textAlign: "justify" }} className="pt-4">
                                Preparing for postgraduate studies? CUET PG 2025 is your gateway to a wide array of
                                postgraduate programs in prominent Indian universities. This comprehensive guide will equip you
                                with all the essential information about the CUET PG Exam 2025, empowering you to navigate the
                                application process and ace the examination</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-11 p-4 ms-sm-4">
                        <h4>RELATED BLOGS</h4>
                        <PerfectScrollbar style={{ height: '100vh' }}>
                            <div className="blog-preview-container">
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">Jan 16,2025</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-PG-History-Question-Paper"} style={{ cursor: "pointer" }}>
                                            CUET PG 2025: Your Comprehensive Guide to Success
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">December 23,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-PG-History-Question-Paper"} style={{ cursor: "pointer" }}>
                                            CUET PG History Question Paper: Download with Answer Key PDF                                    </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 07,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/prepare-cuet-pg-english-literature"} style={{ cursor: "pointer" }}>
                                            How to prepare for CUET PG English Literature
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">October 28,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-pg-online-coaching"} style={{ cursor: "pointer" }}>
                                            CUET PG Online Coaching: Your Path to Success
                                        </h5>
                                    </div>
                                </div>

                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">August 07,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-preparation"} style={{ cursor: "pointer" }}>
                                            Mastering CUET: The Ultimate Guide to CUET Preparation and Success
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/blog"} style={{ cursor: "pointer" }}>
                                            Class 10 SEBA Preparation Resources: Excel with BESST
                                        </h5>
                                    </div>
                                </div>


                            </div>
                        </PerfectScrollbar>
                    </div>

                    <div className="container-fluid">

                        <div className="container mt-4">

                            <Row className="gs-5 ge-5">
                                <Col md={12} className='text-center mt-4 mb-4'>
                                    <h2 className='htwo_size '> CUET PG 2025 Application Process</h2>
                                    <p>The application process for CUET PG 2025 is completely online. Follow these steps to apply:</p>
                                </Col>

                                <Col md={4} >
                                    <Card className="custom-card text-center h-100 ">
                                        <div className="red-banner">
                                            <h5 className="hfive_size" > Step 1: Registration                                            </h5>

                                        </div>
                                        <Card.Body className="custom-card-body">
                                            <Card.Text >
                                                <li style={{ marginBottom: '10px' }}>
                                                    Visit the official NTA CUET PG website: <a href="https://exams.nta.ac.in/CUET-PG/" className="text-primary">https://exams.nta.ac.in/CUET-PG/</a>
                                                </li>
                                                <li style={{ marginBottom: '10px' }}>
                                                    Click on the "New Registration" link and create an account by providing your basic details.
                                                </li>
                                                <li>
                                                    Note down the system-generated Application Number and create a secure password.
                                                </li>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4} >
                                    <Card className="custom-card text-center  h-100">
                                        <div className="red-banner">

                                            <h5 className="hfive_size" >Step 2: Filling the Application Form</h5>

                                        </div>
                                        <Card.Body className="custom-card-body">
                                            <Card.Text>
                                                <li style={{ marginBottom: '10px' }}>
                                                    Log in to your account using your Application Number and password.
                                                </li>
                                                <li style={{ marginBottom: '10px' }}>
                                                    Fill in the required personal, academic, and contact details carefully
                                                </li>
                                                <li>
                                                    Select the exam cities based on your preference and the desired postgraduate programs.
                                                </li>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card className="custom-card text-center  h-100">
                                        <div className="red-banner">
                                            <h5 className="hfive_size" > Step 3: Uploading Documents</h5>

                                        </div>
                                        <Card.Body className="custom-card-body">
                                            <Card.Text>
                                                <li style={{ marginBottom: '10px' }}>
                                                    Upload scanned images of your recent photograph, signature, and PwD certificate (if applicable), adhering to the specified size and format guidelines.
                                                </li>

                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={2}
                                ></Col>
                                <Col md={4} className="mt-3">
                                    <Card className="custom-card text-center  h-100">
                                        <div className="red-banner">
                                            <h5 className="hfive_size" >Step 4: Fee Payment</h5>

                                        </div>
                                        <Card.Body className="custom-card-body">
                                            <Card.Text>
                                                <li style={{ marginBottom: '10px' }}>
                                                    Pay the application fee online using Net Banking, Credit/Debit Card, UPI, or Wallet. The fee
                                                    structure is based on the category and the number of test papers chosen.

                                                </li>
                                                <li style={{ marginBottom: '10px' }}>
                                                    Carefully review the fee details before making the payment.
                                                </li>

                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Card className="custom-card text-center  h-100">
                                        <div className="red-banner">
                                            <h5 className="hfive_size" > Step 5: Downloading Confirmation Page</h5>

                                        </div>
                                        <Card.Body className="custom-card-body">
                                            <Card.Text>
                                                <li style={{ marginBottom: '10px' }}>
                                                    After successful fee payment, download and print the Confirmation Page for future
                                                    reference.
                                                </li>

                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={2}
                                ></Col>

                                <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className="mt-3 rounded">
                                    <div className="p-3">
                                        <h3 className="hthree_size mb-2" style={{ color: 'orange' }}> Important Instructions</h3>

                                        <li style={{ marginBottom: '10px' }}>
                                            Only one application form per candidate is accepted. Multiple applications will be rejected.
                                        </li>
                                        <li style={{ marginBottom: '10px' }}>
                                            Thoroughly check all the information before final submission, as changes might not be possible later.
                                        </li>

                                        For any assistance during the application process, refer to the official NTA website or contact the
                                        helpline: helpdesk-cuetpg@nta.ac.in.
                                    </div>
                                </div>
                            </Row>
                        </div>

                        <div className="col-md-12 mt-5">
                            <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                                <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                                    <h2 className="htwo_size text-start" > CUET PG 2025 Admit Card</h2>
                                    <p style={{ fontSize: '1rem' }} className="mt-3">
                                        The admit card for CUET PG 2025 will be available for download from the official NTA website a
                                        few days before the examination. The admit card will contain crucial information such as your roll
                                        number, exam center, date, and shift. It is mandatory to carry a printed copy of the admit card and a
                                        valid photo ID proof to the exam center. For any issues related to admit card download, contact the
                                        NTA helpline: 011-40759000.
                                    </p>

                                    <div>
                                        <h3 className="hthree_size mt-5" style={{}}> Preparing for CUET PG 2025</h3>
                                        <p style={{ fontSize: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'start' }} className="mt-1">
                                            To enhance your CUET PG 2025 preparation, consider Brahmaputra Exam Success Support
                                            Team (BESST), the leading choice for CUET (PG) guidance. BESST provides a wide range of
                                            resources to help you succeed:
                                        </p>
                                        <li style={{ marginBottom: '5px' }}>
                                            <strong style={{ color: 'black' }}> Mock Tests:</strong> Practice with BESST's meticulously crafted mock tests to familiarize yourself
                                            with the exam pattern and enhance time management skills. Access mock tests at
                                            <a href="https://besst.in/cuet-mock-test." className="text-primary"> https://besst.in/cuet-mock-test.</a>
                                        </li>
                                        <li style={{ marginBottom: '5px' }}>
                                            <strong style={{ color: 'black' }}> Previous Year Question Bank:</strong> Gain valuable insights into the types of questions asked in
                                            previous years and identify recurring patterns. Access the question bank at
                                            <a href="https://besst.in/previous-year-question-bank." className="text-primary"> https://besst.in/previous-year-question-bank.</a>
                                        </li>
                                        <li style={{ marginBottom: '5px' }}>
                                            <strong style={{ color: 'black' }}> Downloadable Study Materials:</strong>  Supplement your preparation with BESST's
                                            comprehensive study materials, covering essential concepts and topics. Download the
                                            materials at <a href="https://besst.in/download-study-material" className="text-primary"> https://besst.in/download-study-material</a>
                                        </li>
                                        <p className="mb-2 mt-2">In addition to utilizing these valuable resources from BESST, follow these preparation tips:</p>
                                        <li style={{ marginBottom: '5px' }}>
                                            <strong style={{ color: 'black' }}> Create a Study Plan:</strong>  Design a structured study plan, allocating sufficient time for each
                                            subject, revision, and mock tests.
                                        </li>
                                        <li style={{ marginBottom: '5px' }}>
                                            <strong style={{ color: 'black' }}> Practice Time Management:</strong> Solve mock tests under timed conditions to improve your
                                            speed and accuracy.
                                        </li>
                                        <li style={{ marginBottom: '5px' }}>
                                            <strong style={{ color: 'black' }}> Focus on Revision:</strong> Regularly revise the concepts you have learned to ensure better
                                            retention.
                                        </li>
                                        <p>
                                            By combining BESST's resources with a strategic approach to preparation, you can boost your
                                            confidence and achieve success in the CUET PG 2025 exam.
                                        </p>
                                        <p>
                                            For more detailed preparation tips and strategies, check out our blog post on
                                            <a href="https://besst.in/blogs" className="text-primary"> https://besst.in/blogs</a>
                                        </p>

                                    </div>
                                </div>

                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/online_pg/2250x1500_czy-warto-korzystac-ze-szkolen-online-ollh.jpg`}
                                    className="courseimg img-fluid"
                                    rounded
                                    alt="CTET"
                                    style={{ objectFit: 'cover', width: '600px', marginLeft: "0px" }}
                                />
                            </div>
                        </div>

                        <div className="mt-4 mb-4 p-4">
                            <h3 className="htwo_size mt-4">CUET PG 2025 Important Dates                            </h3>
                            <p className="mt-1 mb-2">Stay ahead in your preparation by keeping track of the important dates. The tentative schedule for
                                CUET PG 2025 is as follows:
                            </p>
                            <table className="table" style={{
                                textAlign: 'left',
                                color: 'black',
                                background: '#7b1fa229',
                                border: '6px solid #7b1fa2',
                                // borderRadius: '118px',
                            }}
                            >
                                <tbody>

                                    <tr className="text-center">
                                        <td style={{ color: 'black', fontWeight: '600' }}>Activity </td>
                                        <td style={{ color: 'black', fontWeight: '600' }}>Important Date(s)</td>
                                    </tr>

                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Online Submission of Application Form</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}>02 January 2025 to 01 February 2025 (up to
                                            11:50 P.M.)</td>
                                    </tr>

                                    <tr >
                                        <td style={{ color: 'black', fontWeight: '500' }}>Last Date of Successful Transaction of
                                            Examination Fee</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}> 02 February 2025 (up to 11:50 P.M.)</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Correction in Particulars  of Application Form</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}> 03 February 2025 to 05 February 2025</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Advance City Intimation</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}>First Week of March 2025</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Downloading of Admit Card</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}>3-4 days before the examination</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Date of Examination</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}>13 March 2025 to 31 March 2025</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Answer Key Challenges</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}>To be announced on the website</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Declaration of Result</td>
                                        <td style={{ color: 'black', fontWeight: '500' }}>To be announced on the website</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black', fontWeight: '500' }}>Note: These dates are tentative and subject to change. It is crucial to regularly visit the official NTA
                                            website for the latest updates.</td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div className="col-md-12 mt-5">
                            <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/online_pg/gettyimages-692236966-612x612.jpg`}
                                    className="courseimg img-fluid"
                                    rounded
                                    alt="CTET"
                                    style={{ objectFit: 'cover', width: '600px' }}
                                />


                                <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">
                                    <h2 className="htwo_size text-start" > What is CUET PG 2025?</h2>


                                    <div style={{ fontSize: '1rem', lineHeight: '1.6', paddingLeft: '20px' }} className="mt-3">
                                        <p>The Common University Entrance Test (Postgraduate) 2025, popularly known as CUET PG
                                            2025, is a national-level entrance examination for admission to various postgraduate programs
                                            offered by Central Universities, State Universities, and other Participating Universities/
                                            Institutions/Organizations in India. Conducted by the National Testing Agency (NTA), CUET PG
                                            provides a single platform for students aspiring to pursue higher education, streamlining the
                                            admission process across multiple universities.</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div>

                            <div className="container mt-4">

                                <Row className="gs-5 ge-5">
                                    {/* Breadcrumb Card */}
                                    <Col md={12} className='text-center mt-4 mb-4'>
                                        <h2 style={{ color: '#5a1e7d', fontSize: '1.5em' }} className='htwo_size'>CUET PG 2025 Eligibility Criteria</h2>
                                        <p>To appear for CUET PG 2025, you must meet the following eligibility criteria:</p>
                                    </Col>

                                    <Col md={6} >
                                        <Card className="custom-card text-center h-100 ">
                                            <div className="red-banner">
                                                <h5>Educational Qualification</h5>
                                            </div>
                                            <Card.Body className="custom-card-body">
                                                <Card.Text >
                                                    <div style={{ fontSize: '16px' }}>
                                                        You should have passed or be appearing in the final year of a
                                                        Bachelor’s degree or equivalent examination in 2025
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    {/* Search Field Card */}
                                    <Col md={6} >
                                        <Card className="custom-card text-center  h-100">
                                            <div className="red-banner">
                                                <h5>Age Limit</h5>
                                            </div>
                                            <Card.Body className="custom-card-body">
                                                <Card.Text>
                                                    <div style={{ fontSize: '16px' }}>

                                                        There is no age limit to apply for the CUET PG 2025 examination.

                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    {/* Pagination Card */}


                                    {/* Image Carousel Card */}

                                </Row>
                            </div>
                            <p className="p-5"><strong style={{ color: 'black' }}>Note:</strong> While there is no general age limit, individual universities may have specific age criteria for
                                their programs. Ensure to check the eligibility requirements of your desired universities before
                                applying.
                            </p>

                        </div>

                        <div className="mt-5 row-flex row">


                            <div className="col-md-12 p-5">
                                <div class="card mb-3 mb-0"
                                    style={{
                                        background: '#71269C44',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'normal',
                                        alignItems: 'normal',
                                        gap: '0r',
                                        padding: '0px',
                                    }}>
                                    <div class="row ">

                                        <div class="col-md-8">
                                            <div class="card-body ms-4" >
                                                <h5 class="card-title hthree_size" style={{
                                                    height: '100%'
                                                }}
                                                >CUET PG 2025 Exam Pattern and Syllabus</h5>
                                                <p class="card-text">CUET PG 2025 will be conducted in online mode as a Computer-Based Test (CBT). The exam
                                                    pattern is as follows</p>
                                                <li style={{ color: 'black' }}>
                                                    <strong style={{ color: 'black' }}>Exam Duration: </strong>  90 minutes for each paper</li>
                                                <li style={{ color: 'black' }}>
                                                    <strong style={{ color: 'black' }}>Number of Questions:</strong>   75 multiple-choice questions (MCQs) per paper</li>
                                                <li style={{ color: 'black' }}>
                                                    <strong style={{ color: 'black' }}>Marking Scheme:</strong>
                                                    <li style={{ color: 'black' }}>
                                                        4 marks for each correct answer</li>
                                                    <li style={{ color: 'black' }}>
                                                        1 mark deducted for each incorrect answer</li>
                                                    <li style={{ color: 'black' }}>
                                                        No marks for unattempted questions</li>
                                                </li>
                                                <li style={{ color: 'black' }}>
                                                    <strong style={{ color: 'black' }}>Medium of Examination:</strong>  English and Hindi (Bilingual), except for language papers,
                                                    M.Tech/Higher Sciences, and Acharya papers (except Hindu Studies, Baudha Dharshan, and
                                                    Indian Knowledge System). For language papers, the medium will be the respective
                                                    language of the subject.</li>
                                                <p class="card-text"><small class="text-muted">The syllabus for all CUET PG 2025 papers is available on the official NTA website. Familiarize
                                                    yourself with the syllabus of your chosen subject to structure your preparation effectively</small></p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <img src={`${process.env.PUBLIC_URL}/assets/cuetimg/bscdegreecourse-1647858929.jpg`} class="h-100 w-100 mb-0 img-fluid rounded-end" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-12 mt-5">
                            <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                                <div className="cuetctn ms-0 me-0 me-sm-5 mb-5 ms-lg-5 mb-l">
                                    <h2 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='htwo_size'> CUET PG 2025 Results and Counseling</h2>
                                    <p style={{ textAlign: "justify", fontSize: '18px' }} className='mt-2 mb-2'>
                                        The NTA will announce the CUET PG 2025 results on its official website after the examination.
                                        You can check your scorecard by logging in with your credentials. Remember, the NTA only
                                        releases the scorecard. The counseling and admission process will be conducted individually by the
                                        participating universities based on the CUET PG score.
                                        For information about counseling schedules, admission procedures, and merit lists, visit the official
                                        website of the university you wish to apply to. The NTA does not handle any admission-related
                                        procedures
                                    </p>
                                    <p style={{ textAlign: "justify", fontSize: '18px' }} className='mt-2 mb-2'>
                                        The fee structure for the CUET PG 2025 exam is as follows:

                                    </p>
                                    <div style={{ fontSize: '1rem', lineHeight: '1.6', paddingLeft: '20px' }} className="mt-3">
                                        <li style={{ marginBottom: '10px' }}>
                                            <strong style={{ color: 'black' }}> For General Category Candidates:</strong> The applicati1on fee is ₹ 1400 for up to two test papers. If a
                                            candidate wishes to apply for more than two test papers, an additional fee of ₹ 700 per test paper is
                                            required.</li>
                                        <li style={{ marginBottom: '10px' }}>
                                            <strong style={{ color: 'black' }}> For OBC-NCL/Gen-EWS Category Candidates: </strong>  The application fee is ₹ 1200 for up to two
                                            test papers. An additional fee of ₹ 600 per test paper will apply for candidates applying for more
                                            than two test papers</li>
                                        <li>
                                            <strong style={{ color: 'black' }}>For SC/ST/Third Gender Category Candidates: </strong>  Candidates in this category are required to
                                            pay ₹ 1100 as the application fee for up to two test papers. For those applying for more than two
                                            test papers, an additional fee of ₹ 600 per test paper will be applicable</li>
                                        <li>
                                            <strong style={{ color: 'black' }}>For PwBD Category Candidates: </strong>  The application fee for up to two test papers is ₹ 1000. For
                                            each additional test paper beyond two, candidates need to pay ₹ 600 per test paper</li>
                                        <p>
                                            <strong style={{ color: 'black' }}>Candidates choosing test centers outside of India </strong> are subject to a different fee structure. They
                                            will need to pay a fee of ₹ 6000 for up to two test papers, regardless of their category. Additional
                                            fees apply for those opting for more than two test papers. </p>
                                    </div>
                                </div>


                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/online_pg/group-young-indian-students-sitting-auditorium-lecture-row-smiling_538002-3755.jpg`}
                                    className="courseimg img-fluid"
                                    rounded
                                    alt="CTET"
                                    style={{ objectFit: 'cover', width: '600px', height: 'auto' }}
                                />

                            </div>
                        </div>

                        <div className="blog-faq mt-4">
                            <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                                <div>
                                    <h2 className="htwo_size">Frequently Asked Questions (FAQs) about CUET PG 2025                                    </h2>
                                </div>
                            </Col>

                            <Col md={12} sm={12} xs={12} className="faq_margin">

                                <Accordion
                                    data-responsive-accordion-tabs="-medium-tabs large-tabs"

                                    className="w-100"
                                >
                                    <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                                        <Accordion.Header className="accordion_header w-100">
                                            What is the age limit for CUET PG 2025?

                                        </Accordion.Header>
                                        <Accordion.Body>There is no age limit to appear for CUET PG 2025. However, individual universities might have
                                            specific age restrictions.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                                >
                                    <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                                        <Accordion.Header className="accordion_header w-100">
                                            What is the mode of the CUET PG 2025 exam?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            The exam will be conducted in online mode as a Computer-Based Test (CBT).
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"
                                >
                                    <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                                        <Accordion.Header className="accordion_header w-100">
                                            Where can I find the syllabus for CUET PG 2025?

                                        </Accordion.Header>
                                        <Accordion.Body>
                                            The syllabus is available on the official NTA CUET PG website: <a href="https://exams.nta.ac.in/CUET-PG/">
                                                https://exams.nta.ac.in/CUET-PG/</a>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <Accordion data-responsive-accordion-tabs="-medium-tabs large-tabs" className="w-100"                                >
                                    <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                                        <Accordion.Header className="accordion_header w-100">
                                            Whom should I contact for admission-related queries after the CUET PG 2025 results?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            For all admission-related queries, contact the respective university you have applied to. The NTA
                                            does not handle admissions.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </div>

                        <div className="p-5">
                            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className="mt-3 p-5 rounded">
                                <h3 className="hthree_size">
                                    Conclusion
                                </h3>
                                <p className="mt-3">
                                    CUET PG 2025 offers a valuable opportunity for students seeking admission to prestigious
                                    postgraduate programs across India. Equip yourself with the right information, prepare strategically,
                                    and confidently take on the exam. Best of luck!

                                </p>
                            </div>
                        </div>
                    </div>
                </div >
                < Blog />
            </>
        </div>
    )
}
