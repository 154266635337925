import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Card, Button, Form } from "react-bootstrap";
import {
  FaEye,
  FaEyeSlash,
  FaGoogle,
  FaFacebook,
  FaApple,
  FaCheck,
} from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import "../Signin/signinstyle.css";
import { useNavigate } from "react-router-dom";
import { EncryptText } from "../../../Encrypt/CryptoEncryption";
import Swal from "sweetalert2";


function Sign_up() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [copyToWhatsapp, setCopyToWhatsapp] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("1");
  const [uid, setUid] = useState(location.state?.profileObj.uid || "");
  const [clinetLocation, setClinetLocation] = useState({ lat: "", lon: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isSmallScreen = window.innerWidth <= 500;
  const [otpResent, setOtpResent] = useState(false);
  const [otpFailureMessage, setOtpFailureMessage] = useState("");
  const [isShowWrongOTP, setIsShowWrongOTP] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [validationId, setValidationId] = useState("4");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const inputs = useRef([]);
  const [courses, setCourseDetails] = useState([]);
  useEffect(() => {
    if (copyToWhatsapp) {
      setWhatsappNumber(phone);
    }
  }, [copyToWhatsapp, phone]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < inputs.current.length - 1 && value !== "") {
      inputs.current[index + 1].focus();
    }
  };

  useEffect(() => {
    axios
      .post(
        baseURL + "/df/coursesAndTopics/",
        {
          courseId: "0",
        },
        {
          headers: {
            "Acces-Control-Allow-Origin": "*",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      )
      .then((response) => {
        // setLoading(false);
        if (response.status === 200) {
          setCourseDetails(response.data.Data);
          // window.location.reload();
          // console.log(courses);
        } else {
          setCourseDetails([]);
        }
      })
      .catch((e) => {
        // console.log(e);
        alert("Please Check Details");
        setCourseDetails([]);
        // setLoading(false);
      });
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleNext = async () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (step === 1) {
      if (!email) {
        Swal.fire({
          icon: "error",
          title: "Testing",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      if (!emailRegex.test(email)) {
        Swal.fire({
          icon: "error",
          title: "Please enter a valid email",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

    } else if (step === 2) {
      if (phone == "") {
        Swal.fire({
          icon: "error",
          title: "Please enter phone number",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      if (!phoneRegex.test(phone)) {
        Swal.fire({
          icon: "error",
          title: "Please enter valid phone number!",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        return;
      }
      if (whatsappNumber == "") {
        Swal.fire({
          icon: "error",
          title: "Please enter the whatsapp number.",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        return;
      }
      if (!phoneRegex.test(whatsappNumber) && whatsappNumber != "") {
        Swal.fire({
          icon: "error",
          title: "Please enter valid whatsapp number!",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

    } else if (step === 3) {
      if (!name) {
        Swal.fire({
          icon: "error",
          title: "Please enter your name",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
    } else if (step === 4) {
      if (password.length < 6) {
        Swal.fire({
          icon: "error",
          title: "Minimum 6 digits required for password",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      if (!(password === confirmPassword)) {
        Swal.fire({
          icon: "error",
          title: "Passwords do not match",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
    } else if (step === 5) {
      setButtonDisabled(true);
      const response = await axios
        .post(`${baseURL}/wl/sendOTP`, {
          EmailId: EncryptText(email),
          OtpType: `1`,
          Mobile: EncryptText(phone),
          WhatsappNo: whatsappNumber ? EncryptText(whatsappNumber) : ""
        });

      if (response.data.ResultCode == "403") {
        Swal.fire({
          icon: "error",
          title: `${response.data.ResultMessage}`,
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      setButtonDisabled(false);
    } else if (step === 6) {
      // console.log(otp.join(""), "<<<<<<<<<<<<<<<<<<<<<");
      axios.post(
        `${baseURL}/wl/verifyOTP`,
        {
          EmailId: EncryptText(email),
          Otp: EncryptText(otp.join("")),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      ).then((res) => {
        if (res.data.ResultCode == "403") {
          Swal.fire({
            icon: "error",
            title: `${res.data.ResultMessage}`,
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return;
        }

        axios
          .post(
            `${baseURL}/df/userRegDetails`,
            {
              email: EncryptText(email),
              firstName: name.split(" ")[0],
              lastName: name.split(" ")[1],
              uid: uid,
              number: EncryptText(phone),
              whatsappNumber: EncryptText(whatsappNumber),
              password: EncryptText(password),
              course: selectedCourse,
              latitude: clinetLocation.lat,
              longitude: clinetLocation.lon,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
              },
            }
          )
          .then((response) => {
            if (response.data.ResultCode === "200") {
              Cookies.set("token", `Bearer ${response.data.Data.LoginToken}`);
              Cookies.set("email", response.data.Data.Email);
              Cookies.set("userId", response.data.Data.UserId);
              Cookies.set("preferredCourseId", selectedCourse);
              navigate("/student-dashboard");
            } else {
              // console.log(response.data);
              Swal.fire({
                icon: "error",
                title: response.data.ResultMessage,
                toast: true,
                position: "bottom-right",
                customClass: {
                  popup: "colored-toast",
                },
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: error,
              toast: true,
              position: "bottom-right",
              customClass: {
                popup: "colored-toast",
              },
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            // console.error("Axios error:", error);
          });
      }).catch((error) => {
        Swal.fire({
          icon: "error",
          title: error,
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      });
    }

    if (step < 6) {
      // console.log("STEP4");
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setButtonDisabled(false);
    setStep((step) => step - 1);
  };

  const handleResendOtp = async () => {
    let name;
    if (validationId === "5") name = "EmailId";
    else if (validationId === "4") name = "Mobile";
    else if (validationId === "6") name = "WhatsappNo";

    try {
      setOtpResent(true);
      const response = await axios.post(
        `${baseURL}/wl/sendOTP`,
        {
          [`${name}`]: EncryptText(email),
          OtpType: (name == "Mobile" ? "4" : "5"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      );

      if (response.data.ResultCode === "200") {
        setStep(2);
        Swal.fire({
          icon: "success",
          title: "OTP Sent",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error sending OTP",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      // console.error("Error during email/OTP request", error);
      Swal.fire({
        icon: "success",
        title: "Error sending OTP",
        toast: true,
        position: "bottom-right",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/cardimg/7a4c0d6ee7cceed8cf02a566d9596667.png")`,
        backgroundSize: "cover",
        height: "100%",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(123, 31, 162, 0.5)",
      }}
    >
      <div
        style={{
          backgroundSize: "cover",
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          padding: "50px",
          backgroundColor: "rgba(123, 31, 162, 0.5)",
        }}
      >
        <Card
          style={{ width: "700px", padding: "50px", borderRadius: "2px", backgroundColor: "#fff" }}
          className="cards-container card"
        >
          <h4 className="sign_in_head text-center">{isSmallScreen ? "Sign Up" : "Sign Up To Your Account"}</h4>
          {
            step !== 6 ? (<div className="text-center mb-3">
              <div className="container">
                <div className="imagectn">
                  <div className="circlespan">
                    {step >= 1 && (
                      <FaCheck
                        style={{
                          color: "white",
                          backgroundColor: "#71269C",
                          borderRadius: "50%",
                          height: "70%",
                          width: "70%",
                          padding: "8px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <span className="horizontal-line"></span>
                <div className="imagectn">
                  <div className="circlespan">
                    {step >= 2 && (
                      <FaCheck
                        style={{
                          color: "white",
                          backgroundColor: "#71269C",
                          borderRadius: "50%",
                          height: "70%",
                          width: "70%",
                          padding: "8px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <span className="horizontal-line"></span>
                <div className="imagectn">
                  <div className="circlespan">
                    {step >= 3 && (
                      <FaCheck
                        style={{
                          color: "white",
                          backgroundColor: "#71269C",
                          borderRadius: "50%",
                          height: "70%",
                          width: "70%",
                          padding: "8px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <span className="horizontal-line"></span>
                <div className="imagectn">
                  <div className="circlespan">
                    {step >= 4 && (
                      <FaCheck
                        style={{
                          color: "white",
                          backgroundColor: "#71269C",
                          borderRadius: "50%",
                          height: "70%",
                          width: "70%",
                          padding: "8px",
                        }}
                      />
                    )}
                  </div>
                </div>
                <span className="horizontal-line"></span>
                <div className="imagectn">
                  <div className="circlespan">
                    {step >= 5 && (
                      <FaCheck
                        style={{
                          color: "white",
                          backgroundColor: "#71269C",
                          borderRadius: "50%",
                          height: "70%",
                          width: "70%",
                          padding: "8px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {isSmallScreen ? undefined : (<div className="container textctn2">
                <p>Email</p>
                <p>Phone</p>
                <p>Name</p>
                <p>Password</p>
                <p>Course</p>
              </div>)}
            </div>) : undefined
          }

          <Form onSubmit={(e) => e.preventDefault()} className={isSmallScreen ? 'w-100' : ''} style={isSmallScreen ? {} : { width: '400px' }}>
            <Form.Group className="mb-3">
              <Form.Label>
                {step === 1 ? "Enter Email" : ""}
                {step === 2 ? "Enter Mobile Number" : ""}
                {step === 3 ? "Enter Name" : ""}
                {step === 4 ? "Enter Password" : ""}
                {step === 5 ? "Select Courses" : ""}
                {step === 6 ? "Enter OTP" : ""}
              </Form.Label>

              {step === 6 && (
                <>
                  <div className="d-flex justify-content-center">
                    {[1, 2, 3, 4].map((_, index) => (
                      <input
                        className="otpsize"
                        key={index}
                        type="text"
                        maxLength="1"
                        style={{
                          width: "90px",
                          height: "60px",
                          marginRight: "10px",
                          textAlign: "center",
                          fontSize: "18px",
                          border: "2px solid #71269C",
                          borderRadius: "5px",
                        }}
                        value={otp[index]}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        ref={(input) => {
                          inputs.current[index] = input; // Store the reference to the input field
                        }}
                      />
                    ))}
                  </div>
                  {isShowWrongOTP ? (<small className="text-danger">{otpFailureMessage}</small>) : undefined}
                  <div className="mt-2">
                    <Button
                      variant="link"
                      onClick={handleResendOtp}
                      disabled={otpResent}
                      style={{ color: "#71269C" }}
                    >
                      {otpResent ? "OTP Resent" : "Did not receive OTP? Resend"}
                    </Button>
                  </div>
                </>
              )}

              {step === 5 && (
                <Form.Control
                  as="select"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  className="form-apperance"
                >
                  {courses.map((course) => {
                    return (
                      <option value={course.courseId}>
                        {course.courseName}
                      </option>
                    )
                  })}
                  {/* <option value="1">
                    Common University Entrance Test (CUET)
                  </option>
                  <option value="2">
                    NATIONAL ELIGIBILITY CUM ENTRANCE TEST (NEET)
                  </option>
                  <option value="3">
                    National Talent Search Examination (NTSE)
                  </option>
                  <option value="4">GENERAL APTITUDE TEST</option>
                  <option value="5">NCERT (12th)</option>
                  <option value="6">CLASS 10(SEBA)</option>
                  <option value="7">Staff Selection Commission (SSC)</option>
                  <option value="8">Online Classes</option>
                  <option value="9">CUET (PG)</option>
                  <option value="10">CLASS XII (Crash Course)</option> */}
                </Form.Control>
              )}

              {step === 4 ? (
                <>
                  <Form.Group style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        height: "50px",
                        borderRadius: "0",
                      }}
                    />
                    {showPassword ? (
                      <FaEye
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Form.Group>

                  <Form.Group style={{ position: "relative" }}>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{
                        height: "50px",
                        borderRadius: "0",
                        marginTop: "7px",
                      }}
                    />
                    {showConfirmPassword ? (
                      <FaEye
                        onClick={toggleConfirmPasswordVisibility}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={toggleConfirmPasswordVisibility}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </Form.Group>
                </>
              ) : (
                <>
                  {step === 1 && (
                    <Form.Control
                      type="text"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        height: "50px",
                        borderRadius: "0",
                      }}
                    />
                  )}

                  {step === 2 && (
                    <>
                      <Form.Control
                        type="text"
                        placeholder="Enter Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{
                          height: "50px",
                          borderRadius: "0",
                        }}
                      />
                      <Form.Control
                        type="text"
                        placeholder="Enter WhatsApp number"
                        value={whatsappNumber}
                        onChange={(e) => setWhatsappNumber(e.target.value)}
                        style={{
                          height: "50px",
                          borderRadius: "0",
                          marginTop: "7px",
                        }}
                      />
                      {/* Checkbox to copy phone number to WhatsApp */}
                      <Form.Check
                        type="checkbox"
                        label="Same as Phone Number"
                        checked={copyToWhatsapp}
                        onChange={() => setCopyToWhatsapp(!copyToWhatsapp)}
                        style={{
                          marginTop: "10px",
                        }}
                      />
                    </>
                  )}

                  {step === 3 && (
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{
                        height: "50px",
                        borderRadius: "0",
                      }}
                    />
                  )}
                </>
              )}
            </Form.Group>

            <div
              className="text-center mt-3"
              style={{ display: "flex", gap: "1em" }}
            >
              {step > 1 && (
                <Button
                  variant=""
                  size="lg"
                  style={{
                    width: "48%",
                    background: "#8C94A355",
                    color: "rgb(0, 0, 0)",
                    opacity: 0.8,
                    display: "block",
                    margin: "auto",
                    borderRadius: "2px",
                    fontWeight: "semibold",
                  }}
                  onClick={handlePrev}
                >
                  Prev
                </Button>
              )}
              <Button
                variant=""
                size="lg"
                style={{
                  width: "48%",
                  background: "#8C94A355",
                  color: "rgb(0, 0, 0)",
                  opacity: 0.8,
                  display: "block",
                  margin: "auto",
                  borderRadius: "2px",
                  fontWeight: "semibold",
                }}
                disabled={buttonDisabled}
                onClick={handleNext}
              >
                {step === 6 ? "Sign Up" : "Next"}
              </Button>
            </div>
            {/* <div className="container">
              <span
                style={{ width: "40%", border: "1px solid #08080844" }}
              ></span>
              <p
                style={{
                  color: "gray",
                  fontWeight: "normal",
                  marginBlock: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                SIGN IN WITH
              </p>
              <span
                style={{ width: "40%", border: "1px solid #08080844" }}
              ></span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <button className="providerbtn">
                <div style={{ flex: "0.25" }}>
                  <FcGoogle size={25} />
                </div>
                {isSmallScreen ? '' : 'Google'}
              </button>
              <button className="providerbtn">
                <div style={{ flex: "0.25" }}>
                  <FaFacebook size={25} color="blue" />
                </div>
                {isSmallScreen ? '' : 'Facebook'}
              </button>
              <button className="providerbtn">
                <div style={{ flex: "0.25" }}>
                  <FaApple size={25} color="black" />
                </div>
                {isSmallScreen ? '' : 'Apple'}
              </button> */}
            {/* <Button
              variant="outline-primary"
              className="me-3"
              size="md"
              onClick={() => console.log("Google button clicked")}
            >
              <FaGoogle size={25} />
              Google
            </Button>
            <Button
              variant="outline-primary"
              className="me-3"
              size="md"
              onClick={() => console.log("Facebook button clicked")}
            >
              <FaFacebook size={25} />
              Facebook
            </Button>
            <Button
              variant="outline-primary"
              size="md"
              onClick={() => console.log("Apple button clicked")}
            >
              <FaApple size={25} color="black" />
              Apple
            </Button> */}
            {/* </div> */}
          </Form>

          <div className="span_login_msg mt-2 text-center">
            <span className="span_msg">
              Already have an account?{" "}
              <Link to="/signin">
                <span className="span_login">Sign In</span>
              </Link>
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Sign_up;
